import apiRequest, { request } from 'services/api/request';
import { ROLES_NAMES } from 'types/users';

import { URL } from '../url';

export const getProfile = async () =>
  apiRequest({
    url: URL.ACCOUNTS,
  });

export const createProfile = async () =>
  apiRequest({
    method: 'post',
    url: URL.ACCOUNTS,
    data: { roles: [ROLES_NAMES.storyCreator] },
  });

export const inviteUser = async (
  email: string,
  username: string,
  roles: ROLES_NAMES[],
) =>
  request({
    method: 'post',
    url: URL.ACCOUNTS_INVITE,
    data: { email, username, userRoles: roles },
  });

export default function HaruRobotIcon({ size = 56 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 56 54"
      fill="none"
    >
      <path
        d="M8.39247 2.16516C8.35558 1.66841 8.5167 1.17728 8.84038 0.799828C9.16406 0.422373 9.6238 0.189508 10.1184 0.152459C10.6131 0.11541 11.1021 0.277214 11.478 0.602274C11.8538 0.927334 12.0857 1.38902 12.1226 1.88578L10.2575 2.02547L8.39247 2.16516Z"
        fill="#4B4B4B"
      />
      <path
        d="M43.8813 1.8725C43.9206 1.37593 44.1546 0.915346 44.532 0.592082C44.9094 0.268817 45.3992 0.109348 45.8937 0.148755C46.3881 0.188162 46.8468 0.423217 47.1687 0.802211C47.4906 1.18121 47.6494 1.67309 47.6101 2.16967L45.7457 2.02108L43.8813 1.8725Z"
        fill="#4B4B4B"
      />
      <path
        d="M26.1833 11.7393H29.9239V30.5214H26.1833V11.7393Z"
        fill="#B5B5B5"
      />
      <path
        d="M20.105 8.45215H35.5346L35.067 12.2086H20.5725L20.105 8.45215Z"
        fill="#B5B5B5"
      />
      <path
        d="M10.7537 49.7744H45.8209C45.8209 52.1084 43.9289 54.0004 41.5949 54.0004H14.9796C12.6457 54.0004 10.7537 52.1084 10.7537 49.7744Z"
        fill="#B5B5B5"
      />
      <path
        d="M10.27 47.818C10.27 37.9965 18.2319 30.0346 28.0533 30.0346C37.8748 30.0346 45.8367 37.9965 45.8367 47.818V49.79C45.8367 51.8656 44.1541 53.5482 42.0785 53.5482H14.0281C11.9526 53.5482 10.27 51.8656 10.27 49.79V47.818Z"
        stroke="#4B4B4B"
        strokeWidth="0.90322"
      />
      <ellipse
        cx="17.2999"
        cy="39.9127"
        rx="0.935127"
        ry="0.939107"
        fill="#9D9D9D"
      />
      <ellipse
        cx="36.47"
        cy="39.9127"
        rx="0.935127"
        ry="0.939107"
        fill="#9D9D9D"
      />
      <ellipse
        cx="17.2999"
        cy="42.2604"
        rx="0.935127"
        ry="0.939107"
        fill="#9D9D9D"
      />
      <ellipse
        cx="36.47"
        cy="42.2604"
        rx="0.935127"
        ry="0.939107"
        fill="#9D9D9D"
      />
      <ellipse
        cx="19.6373"
        cy="42.2604"
        rx="0.935127"
        ry="0.939107"
        fill="#9D9D9D"
      />
      <ellipse
        cx="38.8077"
        cy="42.2604"
        rx="0.935127"
        ry="0.939107"
        fill="#9D9D9D"
      />
      <ellipse
        cx="19.6373"
        cy="39.9127"
        rx="0.935127"
        ry="0.939107"
        fill="#9D9D9D"
      />
      <ellipse
        cx="38.8077"
        cy="39.9127"
        rx="0.935127"
        ry="0.939107"
        fill="#9D9D9D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4429 42.2554C22.4429 41.739 22.8615 41.3203 23.378 41.3203C23.8945 41.3203 24.3131 41.739 24.3131 42.2554V43.6681H31.7938V42.2554C31.7938 41.739 32.2124 41.3203 32.7289 41.3203C33.2453 41.3203 33.664 41.739 33.664 42.2554V43.6681H33.6644V44.342C33.6644 44.9241 33.2514 45.4097 32.7024 45.5218C32.6242 45.5378 32.5431 45.5463 32.4601 45.5463C32.4599 45.5463 32.4599 45.5463 32.4597 45.5463H31.7938H24.3131H23.6472C23.2116 45.5463 22.8301 45.3151 22.6186 44.9687C22.5071 44.7861 22.4429 44.5715 22.4429 44.342V42.2554Z"
        fill="#B5B5B5"
      />
      <rect
        x="0.483796"
        y="0.416639"
        width="19.6701"
        height="19.7566"
        rx="4.36556"
        transform="matrix(0.997207 -0.0746898 0.0740616 0.997254 -0.0295056 2.83025)"
        stroke="#4B4B4B"
        strokeWidth="0.90322"
      />
      <rect
        x="0.414607"
        y="0.486084"
        width="19.6701"
        height="19.7566"
        rx="4.36556"
        transform="matrix(0.996839 0.0794438 -0.078776 0.996892 35.5313 1.17267)"
        stroke="#4B4B4B"
        strokeWidth="0.90322"
      />
      <path
        d="M14.6188 12.0574C14.7672 14.0556 13.2748 15.7919 11.2912 15.9405C9.30758 16.0891 7.57527 14.5943 7.42687 12.5961C7.27847 10.5978 8.77083 8.86151 10.7544 8.71294C12.7381 8.56437 14.4704 10.0592 14.6188 12.0574Z"
        stroke="#4B4B4B"
        strokeWidth="1.20429"
      />
      <path
        d="M48.5264 12.607C48.3686 14.6044 46.6292 16.091 44.6463 15.9329C42.6635 15.7749 41.1793 14.0315 41.3372 12.034C41.495 10.0365 43.2344 8.55 45.2173 8.70803C47.2002 8.86605 48.6843 10.6095 48.5264 12.607Z"
        stroke="#4B4B4B"
        strokeWidth="1.20429"
      />
    </svg>
  );
}

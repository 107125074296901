import React, { FC, MouseEvent, ReactNode } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { QRCodeSVG } from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { black, black54, black87, gray, white } from 'view/theme/colors';

interface DevicesListTableModalProps {
  value: string;
  onClose: (e: MouseEvent<HTMLElement>) => void;
  children?: ReactNode;
  open: boolean;
}

export const DevicesListTableModal: FC<DevicesListTableModalProps> = ({
  value,
  ...restProps
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      sx={{
        '& .MuiBox-root': {
          overflow: 'unset',
        },
      }}
      {...restProps}
    >
      <Box
        position="relative"
        bgcolor={white}
        sx={{
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: 8,
          pb: 8,
        }}
        height="content-height"
        width="100%"
        maxWidth={447}
        overflow="hidden"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          position="absolute"
          right={24}
          top={24}
          onClick={restProps.onClose}
          sx={{ cursor: 'pointer' }}
        >
          <CloseIcon fontSize="large" sx={{ color: gray }} />
        </Box>
        <Typography fontSize={24} fontWeight={700} color={black87} pt={8}>
          {t('devicesListPage.scanId')}
        </Typography>
        <Typography color={black54} fontWeight={500} pb={5} pt={2}>
          {t('devicesListPage.scanToLinkYourDevice')}
        </Typography>
        <Box
          p={2}
          padding={4}
          bgcolor={black}
          sx={{ borderRadius: 8 }}
          display="flex"
          alignItems="center"
        >
          <QRCodeSVG
            value={`${value}`}
            width={225}
            height={225}
            bgColor={black}
            fgColor="white"
          />
        </Box>
      </Box>
    </Modal>
  );
};

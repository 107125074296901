import { FC } from 'react';

import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { formatDate, LocaleKeyType } from 'helpers/date-time';
import { useTranslation } from 'react-i18next';
import { StoryStatus } from 'types/stories';
import StoryStatusComponent from 'view/components/StoryStatusComponent';
import { black16, black54 } from 'view/theme/colors';

interface ViewSimpleStoryInfoProps {
  name?: string;
  createdOn?: Date;
  status?: StoryStatus;
  email?: string;
}
const ViewSimpleStoryUserInfo: FC<ViewSimpleStoryInfoProps> = ({
  name = '',
  createdOn = new Date(),
  status,
  email = '',
}) => {
  const { t, i18n } = useTranslation();
  return (
    <Stack direction="row" spacing={1}>
      {(name || email) && (
        <Box
          sx={{
            backgroundColor: 'rgba(26, 26, 26, 0.08)',
            borderRadius: '50%',
            width: 24,
            height: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textTransform: 'uppercase',
          }}
        >
          <Typography>{name?.[0] || email?.[0]}</Typography>
        </Box>
      )}
      <Typography color={black54}>{name}</Typography>
      <Typography color={black16} fontSize={14} fontWeight={700}>
        /
      </Typography>
      <Typography color={black54}>
        {`${t('createdOn')} 
      ${formatDate(createdOn, 'MM/dd/yyyy', i18n.language as LocaleKeyType)}`}
      </Typography>
      {status && (
        <Typography color={black16} fontSize={14} fontWeight={700}>
          /
        </Typography>
      )}
      {status && <StoryStatusComponent status={status} />}
    </Stack>
  );
};

export default ViewSimpleStoryUserInfo;

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/system';
import { black54, black87, green, purple } from 'view/theme/colors';

export const StyledCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    padding: 0.75rem;
    color: ${black54};

    &.Mui-checked {
      color: ${purple};
    }
  }

  &.MuiCheckbox-colorSuccess {
    &.Mui-checked {
      color: ${green};
    }
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    color: ${black87};
    font-size: 0.875rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
  }
`;

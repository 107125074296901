import React from 'react';

import { Badge, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  LIBRARY_PATH_ALL,
  LIBRARY_PATH_DRAFTS,
  LIBRARY_PATH_PUBLISHED,
  LIBRARY_PATH_REJECTED,
  LIBRARY_PATH_SUBMITTED,
} from 'routes/routes';
import { ROLES_NAMES } from 'types/users';
import { black, black54 } from 'view/theme/colors';

import Role from '../Role';

const StyledTabBox = styled(Box)`
  cursor: pointer;
  & .MuiTypography-root {
    color: ${black54};
  }
  &.active .MuiTypography-root {
    cursor: default;
    color: ${black};
  }
`;

export const LIBRARY_DATA_TEST_ID_PREFIX = 'library-tab-title-';

export default function CategoriesTabs({
  tabs,
}: {
  tabs: { title: string; link: string; count?: number }[];
}) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        marginTop: '1.5rem',
      }}
      spacing={5}
      direction="row"
      display="flex"
      width="100%"
      height="4rem"
    >
      {tabs.map((tab) => {
        const tabContent = (
          <StyledTabBox
            display="flex"
            className={tab.link === location.pathname ? 'active' : ''}
          >
            <Badge
              badgeContent={tab.count}
              color="primary"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Typography
                data-testid={`${LIBRARY_DATA_TEST_ID_PREFIX}${tab.link}`}
                variant="h2"
                component="h1"
                onClick={() =>
                  tab.link !== location.pathname ? navigate(tab.link) : null
                }
              >
                {tab.title}
              </Typography>
            </Badge>
          </StyledTabBox>
        );
        switch (tab.link) {
          case LIBRARY_PATH_ALL: {
            return (
              <Role
                key={`Library-${tab.link}`}
                allowedRoles={[ROLES_NAMES.admin]}
              >
                {tabContent}
              </Role>
            );
          }
          case LIBRARY_PATH_PUBLISHED: {
            return (
              <Role
                key={`Library-${tab.title}`}
                allowedRoles={[
                  ROLES_NAMES.storyComposer,
                  ROLES_NAMES.storyCreator,
                  ROLES_NAMES.robotHandler,
                ]}
              >
                {tabContent}
              </Role>
            );
          }
          case LIBRARY_PATH_SUBMITTED: {
            return (
              <Role
                key={`Library-${tab.title}`}
                allowedRoles={[
                  ROLES_NAMES.storyCreator,
                  ROLES_NAMES.storyComposer,
                  ROLES_NAMES.moderator,
                ]}
              >
                {tabContent}
              </Role>
            );
          }
          case LIBRARY_PATH_REJECTED: {
            return (
              <Role
                key={`Library-${tab.title}`}
                allowedRoles={[
                  ROLES_NAMES.storyComposer,
                  ROLES_NAMES.storyCreator,
                ]}
              >
                {tabContent}
              </Role>
            );
          }
          case LIBRARY_PATH_DRAFTS: {
            return (
              <Role
                key={`Library-${tab.title}`}
                allowedRoles={[
                  ROLES_NAMES.storyComposer,
                  ROLES_NAMES.storyCreator,
                ]}
              >
                {tabContent}
              </Role>
            );
          }
          default:
            return <Box key={`Library-${tab.title}`}>{tabContent}</Box>;
        }
      })}
    </Stack>
  );
}

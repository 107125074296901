import React, { FC, MouseEvent } from 'react';

import { BoxProps, TypographyProps } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import RDMP from '../../icons/Rdmp';
import { purple } from '../../theme/colors';

interface DeviceReadyStoryCardRowStatusProps {
  containerProps?: BoxProps;
  textProps?: TypographyProps;
  iconSize?: number;
  text?: string;
  onClick?: () => void;
  icon?: React.ReactNode;
}

export const DeviceReadyStoryCardRowStatusIcon: FC<
  DeviceReadyStoryCardRowStatusProps
> = ({ containerProps, textProps, text, iconSize, onClick, icon }) => {
  const handleClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onClick && onClick();
  };
  return (
    <Box
      display="flex"
      alignItems="center"
      borderRadius="8px"
      bgcolor={'rgba(72, 83, 231, 0.08)'}
      px={3}
      py={1.5}
      onClick={handleClick}
      zIndex={5}
      {...containerProps}
    >
      {icon || <RDMP size={iconSize} />}
      <Typography
        color={purple}
        fontSize={16}
        fontWeight={700}
        ml={1.5}
        {...textProps}
      >
        {text}
      </Typography>
    </Box>
  );
};

import React, {
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useMemo,
  useRef,
  useState,
} from 'react';

import { BoxProps } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import deepCopy from 'helpers/deepCopy';
import { useTranslation } from 'react-i18next';
import { FiltersData } from 'types/filters';
import Chip from 'view/components/Chip';
import Select from 'view/components/Select';
import EraserIcon from 'view/icons/Eraser';
import { black87 } from 'view/theme/colors';

interface FiltersSelectProps extends BoxProps {
  filters: FiltersData;
  // defaultFilters?: FiltersData;
  setFilters: (filters: any) => void;
  menu: ReactNode;
  error?: boolean;
  helperText?: string;
  handleOnClickCleanTagButton?: () => void;
  label?: string;
}

const renderFiltersValue = (activeFilters: number, t: any) => {
  if (!activeFilters) {
    return null;
  }
  return (
    <Box display="flex" sx={{ alignItems: 'center' }}>
      <Typography variant="body2" color={black87}>
        {t('filtering.applied') + ': '}
      </Typography>
      <Chip
        label={activeFilters}
        color="primary"
        size="small"
        sx={{ borderRadius: '1rem', margin: 0 }}
      />
    </Box>
  );
};

const FiltersSelect = ({
  filters,
  setFilters,
  menu,
  error,
  helperText,
  handleOnClickCleanTagButton,
  label,
  ...rest
}: FiltersSelectProps) => {
  const { t } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);

  const selectWidth = useRef<null | HTMLDivElement>(null);

  const width = selectWidth.current?.clientWidth || 320;

  const updatedMenu = useMemo(() => {
    if (React.isValidElement(menu)) {
      return React.cloneElement<
        PropsWithChildren<{ onApply: () => void; width: number }>
      >(
        menu as ReactElement<
          PropsWithChildren<{ onApply: () => void; width: number }>
        >,
        {
          width,
          onApply: () => {
            menu?.props?.onApply?.();
            setIsOpened(false);
          },
        },
      );
    }
    return menu;
  }, [menu, width]);

  const activeFilters = useMemo(
    () =>
      Object.keys(filters).filter((key: string, idx, filtersArray) => {
        return filters[key].checked;
      }).length,
    [filters],
  );

  const intialFilters = useRef<FiltersData | null>(null);

  const handleOpen = () => {
    intialFilters.current = deepCopy(filters);
    setIsOpened(true);
  };

  const handleClose = () => {
    setIsOpened(false);
    if (intialFilters.current) {
      setFilters(intialFilters.current);
      intialFilters.current = null;
    }
  };

  return (
    <Box
      ref={selectWidth}
      width="100%"
      sx={{
        '.MuiInputBase-adornedStart': {
          padding: '0.25rem',
        },
        position: 'relative',
      }}
      {...rest}
    >
      <Select
        id="filters-select"
        fullWidth
        open={isOpened}
        onOpen={handleOpen}
        onClose={handleClose}
        label={label || t('placeholders.tagsSelect')}
        value={activeFilters ? 'Filters' : ''}
        error={error}
        helperText={helperText}
        startAdornment={
          activeFilters ? (
            <IconButton
              sx={{
                margin: '0 0.25rem 0 0',
                visibility: !activeFilters ? 'hidden' : 'visible',
              }}
              size="small"
              onClick={handleOnClickCleanTagButton}
            >
              <EraserIcon />
            </IconButton>
          ) : null
        }
        renderValue={() => renderFiltersValue(activeFilters, t)}
        menu={updatedMenu}
      />
    </Box>
  );
};

export default FiltersSelect;

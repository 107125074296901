import {
  DrsComments,
  DrsCommentsPayload,
  SsComments,
  SsCommentsPayload,
} from 'types/comments';

import { request } from '../request';
import { URL } from '../url';

export const getCommentsForDrs = async (id: string): Promise<DrsComments[]> => {
  const { data } = await request({
    url: URL.DRS_COMMENTS_GET + `/${id}/comments`,
    method: 'GET',
  });

  return data;
};

export const addCommentsForDrs = async (payload: DrsCommentsPayload[]) => {
  await request({
    url: URL.DRS_COMMENTS,
    data: payload,
    method: 'POST',
  });
};

export const getCommentsForSs = async (id: string): Promise<SsComments[]> => {
  const { data } = await request({
    url: URL.SS_COMMENTS_GET + `/${id}/comments`,
    method: 'GET',
  });

  return data;
};

export const addCommentsForSs = async (payload: SsCommentsPayload[]) => {
  await request({
    url: URL.SS_COMMENTS,
    data: payload,
    method: 'POST',
  });
};

import React from 'react';

import { Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import Amplify from 'aws-amplify';
import appConfig from 'config/appConfig';
import { SnackbarProvider } from 'notistack';
import { createRoot } from 'react-dom/client';
import 'translations/i18n';

import { ConfirmationDialogProvider } from './contexts/confirmation-dialog';
import { LoaderProvider } from './contexts/loader';
import reportWebVitals from './reportWebVitals';
import App from './view';
import { SnackbarCloseButton } from './view/components/SnackbarCloseButton';
import ExclamationCircleIcon from './view/icons/ExclamationCircle';
import theme from './view/theme';

Amplify.configure({
  Auth: {
    region: appConfig.AWS_REGION,
    userPoolId: appConfig.COGNITO_USER_POOL_ID,
    identityPoolId: appConfig.AWS_IDENTITY_POOL_ID,
    userPoolWebClientId: appConfig.COGNITO_APP_CLIENT_ID,
  },
  Storage: {
    region: appConfig.AWS_REGION,
    bucket: appConfig.AWS_BUCKET_NAME,
    identityPoolId: appConfig.AWS_IDENTITY_POOL_ID,
  },
});

Amplify.Storage.configure({
  customPrefix: {
    public: `${appConfig.CONTENT_PATH}/`,
    // protected: `${appConfig.CONTENT_PATH}/`,
    // private: `${appConfig.CONTENT_PATH}/`,
  },
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider
          autoHideDuration={2500}
          iconVariant={{
            error: (
              <Box mr={2} mt={1} sx={{ alignItems: 'center' }}>
                <ExclamationCircleIcon />
              </Box>
            ),
          }}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          action={(snackbarKey) => (
            <SnackbarCloseButton snackbarKey={snackbarKey} />
          )}
        >
          <ConfirmationDialogProvider>
            <LoaderProvider>
              <App />
            </LoaderProvider>
          </ConfirmationDialogProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </>,
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import axios from 'axios';

export const parseError = (error: any) => {
  let message = 'Something went wrong';
  if (axios.isAxiosError(error) && error.response?.data) {
    const data = error.response.data;
    message = data.userMessage ? data.userMessage : data.message;
  } else if (error instanceof Error) {
    message = error.message;
  }

  return message;
};

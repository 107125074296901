import { Device, HaruRoutines } from 'types/devices';
import { DeviceReadyStory } from 'types/stories';

import { request } from '../request';
import { generateParams } from '../stories/api-stories.utils';
import { ApiGetListParams, PaginatedResponse } from '../types';
import { URL } from '../url';

export const getAvailableRoutines = async (): Promise<HaruRoutines[]> => {
  const { data } = await request({
    url: URL.AVAILABLE_HARU_ROUTINES,
  });
  return data;
};

export const getDevice = async (
  id: string,
): Promise<Device<DeviceReadyStory>> => {
  const { data } = await request({
    url: URL.DEVICES + '/' + id,
  });
  return data;
};

export const getDevices = async (
  params: ApiGetListParams,
): Promise<PaginatedResponse<Device<string>>> => {
  const { data } = await request({
    url: URL.DEVICES,
    params: generateParams(params),
  });
  return data;
};

export const removeDevice = async (id: string) => {
  const { data } = await request({
    url: URL.DEVICES + '/' + id,
    method: 'DELETE',
  });
  return data;
};

export const shareDeviceAccess = async (
  deviceId: string,
  drsId: string,
): Promise<void> => {
  const { data } = await request({
    url: URL.DEVICES + '/' + deviceId + '/shared/device-ready-stories/' + drsId,
    method: 'POST',
  });
  return data;
};

export const shareBulkDeviceAccess = async (
  deviceId: string,
  ids: string[],
): Promise<void> => {
  const { data } = await request({
    url: URL.DEVICES + '/' + deviceId + '/shared/device-ready-stories',
    method: 'POST',
    data: { ids },
  });
  return data;
};

export const unshareDeviceAccess = async (
  deviceId: string,
  drsId: string,
): Promise<void> => {
  const { data } = await request({
    url: URL.DEVICES + '/' + deviceId + '/shared/device-ready-stories/' + drsId,
    method: 'DELETE',
  });
  return data;
};

export const getSharedDeviceAccess = async (
  id: string,
  params: ApiGetListParams,
): Promise<PaginatedResponse<DeviceReadyStory>> => {
  const { data } = await request({
    url: URL.DEVICES + '/' + id + '/device-ready-stories',
    params: generateParams(params),
  });
  return data;
};

export const bulkDeleteDevices = async (ids: string[]) => {
  const { data } = await request({
    url: URL.DEVICES_BULK_DELETE,
    method: 'POST',
    data: { ids },
  });
  return data;
};

export const bulkUnshareDeviceAccess = async (
  deviceId: string,
  ids: string[],
) => {
  const { data } = await request({
    url: `${URL.DEVICES}/${deviceId}/shared/device-ready-stories/bulkDelete`,
    method: 'POST',
    data: { ids },
  });
  return data;
};

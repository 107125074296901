import React, { FC } from 'react';

import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';

import Checkbox, { CheckboxCustomProps } from '../Checkbox';

const MultiCheckbox: FC<CheckboxCustomProps> = (props) => {
  return (
    <Checkbox
      icon={<CheckBoxOutlineBlankOutlinedIcon />}
      checkedIcon={<CheckBoxOutlinedIcon />}
      indeterminateIcon={<IndeterminateCheckBoxOutlinedIcon />}
      {...props}
    />
  );
};

export default MultiCheckbox;

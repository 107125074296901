import handleSetArrayParam from 'helpers/handleSetArrayParam';

import { ApiGetListParams, DEFAULT_PAGE_SIZE } from '../types';

export const generateParams = (params: Omit<ApiGetListParams, 'url'>) => {
  const {
    limit = DEFAULT_PAGE_SIZE,
    offset,
    orderDirection,
    orderBy,
    searchQuery = '',
    tags,
    status,
    statuses,
    languages,
    ...rest
  } = params;
  return {
    ...(limit && { limit }),
    ...(offset && { offset }),
    ...(orderDirection && { orderDirection }),
    ...(orderBy && { orderBy }),
    ...(searchQuery && { phrase: searchQuery }),
    ...(status && { status }),
    ...handleSetArrayParam(tags, 'tags'),
    ...handleSetArrayParam(languages, 'languages'),
    ...handleSetArrayParam(statuses, 'statuses'),
    ...rest,
  };
};

import Box from '@mui/material/Box';
import { black54, white } from 'view/theme/colors';

export default function ArrowDownIcon({ style = {} }) {
  return (
    <Box sx={{ display: 'flex' }} style={style}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.355 11.6421L17.355 11.6421L17.3579 11.645C17.4048 11.6915 17.442 11.7468 17.4673 11.8077C17.4927 11.8686 17.5058 11.934 17.5058 12C17.5058 12.066 17.4927 12.1314 17.4673 12.1923C17.442 12.2532 17.4048 12.3085 17.3579 12.355L17.3564 12.3564L12.361 17.3519C12.3141 17.396 12.2592 17.4306 12.1992 17.4538L12.1885 17.458L12.1779 17.4627C12.1218 17.4874 12.0613 17.5002 12 17.5002C11.9387 17.5002 11.8782 17.4874 11.8221 17.4627L11.8115 17.458L11.8008 17.4538C11.7408 17.4306 11.6859 17.396 11.639 17.3519L6.64356 12.3564C6.54902 12.2619 6.49591 12.1337 6.49591 12C6.49591 11.8663 6.54902 11.7381 6.64356 11.6436C6.73809 11.549 6.86631 11.4959 7 11.4959C7.13362 11.4959 7.26177 11.549 7.35629 11.6434C7.35634 11.6434 7.3564 11.6435 7.35645 11.6436L10.6459 14.943L11.5 15.7997V14.59V7C11.5 6.86739 11.5527 6.74022 11.6464 6.64645C11.7402 6.55268 11.8674 6.5 12 6.5C12.1326 6.5 12.2598 6.55268 12.3536 6.64645C12.4473 6.74022 12.5 6.86739 12.5 7V14.59V15.7997L13.3541 14.943L16.6441 11.643L16.645 11.6421C16.6915 11.5952 16.7468 11.558 16.8077 11.5327C16.8686 11.5073 16.934 11.4942 17 11.4942C17.066 11.4942 17.1314 11.5073 17.1923 11.5327C17.2532 11.558 17.3085 11.5952 17.355 11.6421Z"
          fill={white}
          stroke={black54}
        />
      </svg>
    </Box>
  );
}

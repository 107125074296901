import { BoxProps } from '@mui/material';
import Box from '@mui/material/Box';

const MainContainer = ({ children, ...props }: BoxProps) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    flexGrow={1}
    padding={0}
    margin="4rem 0 0 0"
    minHeight="calc(100vh - 4rem)"
    minWidth={0}
    {...props}
  >
    {children}
  </Box>
);

export default MainContainer;

import { purple } from 'view/theme/colors';

export default function EraserIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 19H8.00002L4.00002 15C3.6123 14.6099 3.6123 13.98 4.00002 13.59L14 3.58999C14.3901 3.20226 15.02 3.20226 15.41 3.58999L20.41 8.58999C20.7977 8.98002 20.7977 9.60995 20.41 9.99999L11.41 19"
        stroke={purple}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 12.3L11.7 6"
        stroke={purple}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

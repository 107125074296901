import { CSSProperties } from 'react';

import { black54, white } from 'view/theme/colors';

type ChevronDownIconProps = {
  style?: CSSProperties;
};

export default function ChevronDownIcon({ style }: ChevronDownIconProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" style={style}>
      <path d="M6 9L12 15L18 9" fill={white} />
      <path
        d="M6 9L12 15L18 9"
        stroke={black54}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

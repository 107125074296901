import { FC, MouseEvent, ReactNode, useState } from 'react';

import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { Box, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { formatDate, LocaleKeyType } from 'helpers/date-time';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DEVICES_PATH } from 'routes/routes';
import CopyIcon from 'view/icons/Copy';
import HaruRobotIcon from 'view/icons/HaruRobot';
import { black54, black8, white } from 'view/theme/colors';

import { DevicesListTableModal } from './DevicesListTableModal';

interface DeviceListTableItemProps {
  name: string;
  id: string;
  controls?: ReactNode;
  isCopyAvailable?: boolean;
  updatedDate?: string;
}

export const DeviceListTableItem: FC<DeviceListTableItemProps> = ({
  name,
  id,
  controls,
  isCopyAvailable = true,
  updatedDate,
}) => {
  const navigate = useNavigate();
  const [isQrCodeOpen, setIsQrCodeOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleOpenDevice = () => {
    navigate(`${DEVICES_PATH}/${id}`);
  };

  const handleCopyId = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    navigator.clipboard.writeText(id);
    enqueueSnackbar(t('notifications.idBeenCopied'), {
      variant: 'success',
    });
  };

  const handleOpenQrCode = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsQrCodeOpen(true);
  };

  const handleCloseQrCode = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsQrCodeOpen(false);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      flex={1}
      px={3}
      py={2.5}
      bgcolor={white}
      onClick={handleOpenDevice}
      width="100%"
      maxHeight="96px"
      position="relative"
      borderRadius="16px"
      sx={{
        border: `1px solid ${black8}`,
        cursor: 'pointer',
        '.controls': {
          transition: 'all 0.2s ease-in-out',
          cursor: 'pointer',
          display: 'none',
        },
        '&:hover': {
          background: white,
          border: '1px solid rgba(0, 0, 0, 0.08)',
          boxShadow: '0 8px 24px rgba(0, 0, 0, 0.08)',
          '.controls': {
            display: 'flex',
            alignItems: 'center',
          },
        },
      }}
    >
      <Box flex={0.4} display="flex" alignItems="center">
        <HaruRobotIcon />
        <Box
          pl={3}
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
        >
          <Typography>{name}</Typography>
          {updatedDate && (
            <Typography variant="body2">
              {formatDate(
                new Date(updatedDate),
                'MMM dd, yyyy',
                i18n.language as LocaleKeyType,
              )}
            </Typography>
          )}
        </Box>
      </Box>
      <Stack direction="row" flex={0.6} spacing={1}>
        <Typography>{id}</Typography>
        {isCopyAvailable && (
          <Stack
            direction="row"
            spacing={3}
            alignItems="center"
            className="controls"
            pl={3}
          >
            <Box
              display="flex"
              alignItems="center"
              sx={{ cursor: 'pointer' }}
              onClick={handleCopyId}
            >
              <CopyIcon color={black54} />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              sx={{ cursor: 'pointer' }}
              onClick={handleOpenQrCode}
            >
              <QrCodeScannerIcon sx={{ color: black54 }} />
            </Box>
          </Stack>
        )}
      </Stack>
      {controls}
      {isQrCodeOpen && (
        <DevicesListTableModal
          value={id}
          open={isQrCodeOpen}
          onClose={handleCloseQrCode}
        />
      )}
    </Box>
  );
};

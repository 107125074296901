import { IconButton } from '@mui/material';
import { SnackbarKey, useSnackbar } from 'notistack';

import CloseIcon from '../../icons/Close';
import { white } from '../../theme/colors';

export const SnackbarCloseButton = ({
  snackbarKey,
}: {
  snackbarKey: SnackbarKey | undefined;
}) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <CloseIcon color={white} />
    </IconButton>
  );
};

import { CheckboxProps, FormGroup } from '@mui/material';

import { StyledCheckbox, StyledFormControlLabel } from './styled';

export interface CheckboxCustomProps extends CheckboxProps {
  checked?: boolean;
  label?: string;
  disabled?: boolean;
  className?: string;
  sx?: any;
  labelPlacement?: 'start' | 'end' | 'top' | 'bottom';
  color?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
}

const Checkbox = ({
  checked,
  label,
  color = 'default',
  disabled = false,
  onChange,
  className,
  sx,
  labelPlacement,
  ...restProps
}: CheckboxCustomProps) => {
  if (!label) {
    return (
      <StyledCheckbox
        checked={checked}
        sx={sx}
        disabled={disabled}
        color={color}
        className={className}
        {...restProps}
      />
    );
  }
  return (
    <FormGroup>
      <StyledFormControlLabel
        labelPlacement={labelPlacement}
        control={
          <StyledCheckbox
            checked={checked}
            sx={sx}
            disabled={disabled}
            color={color}
            className={className}
            {...restProps}
          />
        }
        label={label}
      />
    </FormGroup>
  );
};

export default Checkbox;

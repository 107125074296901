import { FC, PropsWithChildren, useLayoutEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { HOME_PATH } from 'routes/routes';
import { ROLES_NAMES } from 'types/users';

import useRole from '../Role/useRole';

interface ProtectedRouteProps {
  allowedRoles: ROLES_NAMES[];
}

const ProtectedRoute: FC<PropsWithChildren<ProtectedRouteProps>> = ({
  allowedRoles,
  children,
}) => {
  const navigate = useNavigate();
  const isAllowed = useRole({ allowedRoles });

  useLayoutEffect(() => {
    if (!isAllowed) {
      navigate(HOME_PATH, { replace: true });
    }
  }, [isAllowed, navigate]);

  return <>{children}</>;
};

export default ProtectedRoute;

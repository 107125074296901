import { FC, ReactNode } from 'react';

import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { ViewSimpleStoryNoContent } from 'view/pages/stories/ViewSimpleStory/components/ViewSimpleStoryNoContent';

interface PropsWithChildren {
  children: ReactNode[];
  isLoading?: boolean;
  title: string;
  isFiltersApplied?: boolean;
}

const NoSearchResultsWrapper: FC<PropsWithChildren> = ({
  children,
  isLoading = false,
  title,
  isFiltersApplied,
}) => {
  const { t } = useTranslation();

  if (isLoading) return null;

  if (children?.length) return <>{children}</>;

  const titleText = isFiltersApplied
    ? t('noResults.noResultsFound')
    : t('noResults.noContent');
  const subtitleText = isFiltersApplied
    ? t('noResults.noResultsFoundDescription')
    : t(`noResults.notFoundContentType`, { title });

  return (
    <Box width="100%" height="100%" display="flex" justifyContent="center">
      <ViewSimpleStoryNoContent
        title={titleText}
        subtitle={subtitleText}
        sx={{ mt: 0 }}
        isFiltersApplied={isFiltersApplied}
      />
    </Box>
  );
};

export default NoSearchResultsWrapper;

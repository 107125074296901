import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import Box from '@mui/material/Box';
import Loader from 'view/components/Loader';

interface LoaderContextValue {
  onShowLoader: () => void;
  onHideLoader: () => void;
  isLoaderShow: boolean;
}

const LoaderContext = createContext<LoaderContextValue>({
  onShowLoader: () => {},
  onHideLoader: () => {},
  isLoaderShow: false,
});

type Props = {
  children: ReactElement;
};

const LoaderProvider = ({ children }: Props) => {
  const [loaderShow, setLoaderShow] = useState<boolean>(false);

  const handleShowLoader = useCallback(() => {
    setLoaderShow(true);
  }, []);

  const handleHideLoader = useCallback(() => {
    setLoaderShow(false);
  }, []);

  const loaderContextValue = useMemo(
    () => ({
      onShowLoader: handleShowLoader,
      onHideLoader: handleHideLoader,
      isLoaderShow: loaderShow,
    }),
    [handleShowLoader, handleHideLoader, loaderShow],
  );

  return (
    <LoaderContext.Provider value={loaderContextValue}>
      {loaderShow && (
        <Box
          zIndex={99999}
          position="fixed"
          left={0}
          top={0}
          right={0}
          bottom={0}
          height="100%"
          sx={{
            backgroundColor: 'white',
            opacity: '30%',
          }}
        >
          <Loader height="100vh" />
        </Box>
      )}
      {children}
    </LoaderContext.Provider>
  );
};

const useLoader = () => {
  return useContext(LoaderContext);
};

export { LoaderProvider, useLoader };

import { SxProps } from '@mui/material';
import MuiAvatar from '@mui/material/Avatar';
import { styled } from '@mui/system';
import { black, black54, black8 } from 'view/theme/colors';

export const StyledAvatar = styled(MuiAvatar)`
  background-color: ${black8};
  width: 3.5rem;
  height: 3.5rem;
  color: ${black};
`;

interface AvatarProps {
  username: string;
  size?: number;
  inactive?: boolean;
  sx?: SxProps;
}

export default function Avatar({
  username,
  size = 3.5,
  inactive,
  sx,
}: AvatarProps) {
  const sizeInRem = `${size}rem`;
  const initials = inactive ? 'D' : username?.[0]?.toUpperCase();

  return (
    <StyledAvatar
      alt={username}
      sx={{
        height: sizeInRem,
        width: sizeInRem,
        fontSize: size === 3 ? '1.3rem' : size === 1 ? '0.7rem' : '1rem',
        background: inactive ? black54 : undefined,
        ...sx,
      }}
    >
      {initials}
    </StyledAvatar>
  );
}

import React, {
  createContext,
  FC,
  MouseEvent,
  PropsWithChildren,
  useContext,
} from 'react';

import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMultiCheckBox from 'hooks/useMultiCheckBox';
import { useTranslation } from 'react-i18next';
import TrashIcon from 'view/icons/Trash';
import { black100, red } from 'view/theme/colors';

import { useConfirmationDialog } from '../../../contexts/confirmation-dialog';
import MultiCheckbox from '../MultiCheckbox';

const CheckBoxContext = createContext<{
  checked: Set<string>;
  checkedArray: string[];
  onChange: (id: string) => void;
  handleCheckAll: (items: string[]) => void;
  handleUncheckAll: () => void;
} | null>(null);

export const useCheckBoxContext = () => {
  const context = useContext(CheckBoxContext);
  if (!context) {
    throw new Error(
      'useCheckBoxContext must be used within a MultiselectCheckboxGroup',
    );
  }
  return context;
};

const MultiselectCheckboxGroup = ({ children }: PropsWithChildren) => {
  const { checked, onChange, handleCheckAll, handleUncheckAll } =
    useMultiCheckBox();

  return (
    <CheckBoxContext.Provider
      value={{
        checked,
        onChange,
        handleCheckAll,
        handleUncheckAll,
        checkedArray: Array.from(checked),
      }}
    >
      {children}
    </CheckBoxContext.Provider>
  );
};

interface MultiselectCheckboxGroupProps<T> {
  items: T[];
  keyExtractor: keyof T;
  label?: string;
  labelPlacement?: 'start' | 'end';
  onDelete?: (ids: string[]) => void;
  sx?: SxProps;
}

const MultiselectCheckboxHeadItem = <T,>({
  items,
  keyExtractor,
  label,
  labelPlacement = 'start',
  onDelete,
  sx,
}: MultiselectCheckboxGroupProps<T>) => {
  const { openDialog } = useConfirmationDialog();
  const { t } = useTranslation();
  const { checked, handleCheckAll, handleUncheckAll } = useCheckBoxContext();

  const handleClickCheckbox = async () => {
    if (checked.size === items.length) {
      handleUncheckAll();
    } else {
      handleCheckAll(items.map((story) => story[keyExtractor] as string));
    }
  };

  const handleClickRemove = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    openDialog({
      text: `${t('modal.removeSelectedElements')}`,
      onConfirm: () => {
        onDelete?.(Array.from(checked));
        handleUncheckAll();
      },
    });
  };

  if (!items.length) return null;

  return (
    <Box display="flex" alignItems="center" sx={sx}>
      {!!checked.size && onDelete && (
        <Box
          display="flex"
          alignItems="center"
          sx={{ cursor: 'pointer' }}
          onClick={handleClickRemove}
        >
          <Typography
            fontSize={14}
            fontWeight={500}
            color={red}
            mr={1}
            textTransform="capitalize"
          >
            {t('remove')}
          </Typography>
          <TrashIcon color={red} />
        </Box>
      )}
      <MultiCheckbox
        checked={checked.size === items.length}
        indeterminate={checked.size > 0 && checked.size < items.length}
        label={label}
        labelPlacement={labelPlacement}
        onClick={handleClickCheckbox}
      />
    </Box>
  );
};

interface MultiselectCheckboxItemProps {
  id: string;
  classNameOnChecked?: string;
  classNameOnUnchecked?: string;
}
const MultiselectCheckboxItem: FC<MultiselectCheckboxItemProps> = ({
  id,
  classNameOnChecked = '',
  classNameOnUnchecked = '',
}) => {
  const { checked, onChange } = useCheckBoxContext();

  const handleClickCheckbox = async (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onChange(id);
  };

  return (
    <Box
      display="flex"
      sx={{
        cursor: 'pointer',
      }}
      className={checked.has(id) ? classNameOnChecked : classNameOnUnchecked}
      onClick={handleClickCheckbox}
    >
      <MultiCheckbox
        checked={checked.has(id)}
        sx={{
          padding: 0,
          '&.MuiButtonBase-root': {
            padding: 0,
          },
        }}
      />
    </Box>
  );
};

interface MultiselectCheckboxGroupTitleProps {
  title: string;
  sx?: SxProps;
}

const MultiselectCheckboxGroupTitle: FC<MultiselectCheckboxGroupTitleProps> = ({
  title,
  sx,
}) => {
  const { checked } = useCheckBoxContext();

  const checkedSize = checked.size ? `(${checked.size})` : '';

  return (
    <Typography fontSize={20} color={black100} sx={sx}>
      {`${title} ${checkedSize}`}
    </Typography>
  );
};

MultiselectCheckboxGroup.MultiselectCheckboxHeadItem =
  MultiselectCheckboxHeadItem;
MultiselectCheckboxGroup.MultiselectCheckboxItem = MultiselectCheckboxItem;
MultiselectCheckboxGroup.MultiselectCheckboxGroupTitle =
  MultiselectCheckboxGroupTitle;

export default MultiselectCheckboxGroup;

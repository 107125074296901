import { deviceReadyStoryPathCreate } from '../routes/routes';
import { copyDeviceReadyStory } from '../services/api/stories/device-ready-stories';

const handleCloneDrs = async (storyId: string) => {
  const newStory = await copyDeviceReadyStory(storyId);
  const newStoryId = newStory.id;
  const origin = window.location.origin;
  window.open(
    `${origin}${deviceReadyStoryPathCreate(
      newStory.originalBasicStoryId,
      newStoryId,
    )}`,
    '_blank',
  );
};

export default handleCloneDrs;

import { useEffect } from 'react';

import { DeviceReadyStory, DRSPackageStatus } from 'types/stories';

import { EventBussEvent } from '../../eventBuss';
import { useSubscribeEventBuss } from '../../eventBuss/useSubscribeEventBuss';

const useRefetchIfDrsPublishing = (
  items: DeviceReadyStory[],
  onRefetch: () => void,
  timeout = 30000,
) => {
  const isDrsPublishing = items.some(
    (item) => item.drsPackage?.status === DRSPackageStatus.inProgress,
  );

  useSubscribeEventBuss(EventBussEvent.drsPublishing, onRefetch);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isDrsPublishing) {
      interval = setInterval(() => {
        onRefetch();
      }, timeout);
    }
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDrsPublishing]);

  return isDrsPublishing;
};

export default useRefetchIfDrsPublishing;

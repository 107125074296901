export enum ListViewMode {
  tile = 'tile',
  row = 'row',
}

export enum StoryTypes {
  simpleStory = 'simpleStory',
  deviceReadyStory = 'deviceReadyStory',
}

export enum LibraryItemTypes {
  all = 'all',
  published = 'published',
  submitted = 'submitted',
  rejected = 'rejected',
  draft = 'draft',
  bookmarks = 'bookmarks',
}

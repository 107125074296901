import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Order, SortProperty } from 'services/api/types';
import ArrowDownIcon from 'view/icons/ArrowDown';
import CheckIcon from 'view/icons/Check';
import { black87, lightGray, purple, white } from 'view/theme/colors';

import DropdownSelect from '../Select';

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: space-between;
  &.Mui-disabled {
    opacity: 1;
  }
  &.Mui-selected {
    background: ${white};
  }
  &.selected p {
    color: ${purple}!important;
  }
  &:hover {
    p {
      color: ${purple};
    }
  }
`;

type SortDropdownProps = {
  items: SortProperty[];
  sorting: [string, Order];
  setSorting: (value: [string, Order]) => void;
};

export default function SortDropdown({
  items,
  sorting,
  setSorting,
}: SortDropdownProps) {
  const { t } = useTranslation();

  const handleSortProperty = (property: string) => {
    const [, orderDirection] = sorting;
    setSorting([property, orderDirection]);
  };

  const handleSortOrder = () => {
    const [orderBy, orderDirection] = sorting;
    const isAsc = orderDirection === Order.ASC;
    setSorting([orderBy, isAsc ? Order.DESC : Order.ASC]);
  };

  return (
    <Box display="flex">
      <Box width="11rem" mr={1}>
        <DropdownSelect
          id="sort-select"
          fullWidth
          label={t('filtering.sortBy')}
          placeholder={t('filtering.sortBy') as string}
          value={sorting[0] ? 'filtering.sortBy' : ''}
          renderValue={() => (
            <Typography variant="body2" color={black87}>
              {items.find(({ value }) => value === sorting[0])?.label}
            </Typography>
          )}
          menu={[
            <StyledMenuItem key="sort-select" disabled sx={{ opacity: 1 }}>
              <Typography
                variant="body2"
                fontSize="0.75rem"
                fontWeight={700}
                sx={{ textTransform: 'uppercase' }}
              >
                {t('filtering.sortingField')}
              </Typography>
            </StyledMenuItem>,
            items.map(({ value, label }) => (
              <StyledMenuItem
                className={value === sorting[0] ? 'selected' : ''}
                key={value}
                value={value}
                onClick={() => handleSortProperty(value)}
              >
                <Typography variant="body2">{label}</Typography>
                {value === sorting[0] && <CheckIcon />}
              </StyledMenuItem>
            )),
          ]}
        />
      </Box>
      <IconButton
        disabled={!sorting[0]}
        style={{ width: '3rem', height: '3rem' }}
        color="primary"
        onClick={handleSortOrder}
        sx={{
          borderRadius: '0.5rem',
          border: '1px solid rgba(0, 0, 0, 0.26)',
          '&:hover': {
            borderColor: black87,
          },
          '&.Mui-disabled': {
            backgroundColor: lightGray,
          },
        }}
        disableRipple
        disableFocusRipple
      >
        {sorting[1] === Order.ASC ? (
          <ArrowDownIcon style={{ transform: 'rotate(180deg)' }} />
        ) : (
          <ArrowDownIcon />
        )}
      </IconButton>
    </Box>
  );
}

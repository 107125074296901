import { FC } from 'react';

import Box from '@mui/material/Box';
import useCheckIfImageValid from 'hooks/useCheckIfImageValid';
import PathNameManager from 'services/pathNameManager';
import EmptyImageIcon from 'view/icons/EmptyImageIcon';
import { black2 } from 'view/theme/colors';

const pathNameManager = new PathNameManager();

interface CardRowImageProps {
  imgUrl: string;
  title: string;
  width?: string;
  height?: string;
  sx?: any;
}
const CardRowImage: FC<CardRowImageProps> = ({
  imgUrl,
  title,
  width = '3.5rem',
  height = '3.5rem',
  sx,
}) => {
  const imgUrlWithPath = pathNameManager.createPathForRendering(imgUrl);

  const isImageValid = useCheckIfImageValid(imgUrlWithPath);

  return (
    <Box display="flex" className="image-container" {...sx}>
      {isImageValid ? (
        <Box
          component="img"
          display="flex"
          width={width}
          height={height}
          borderRadius="0.6rem"
          src={imgUrlWithPath}
          alt={title}
        />
      ) : (
        <Box
          width={width}
          height={height}
          borderRadius={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor={black2}
        >
          <EmptyImageIcon />
        </Box>
      )}
    </Box>
  );
};

export default CardRowImage;

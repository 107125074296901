import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

export default function Loader({ height = '100vh', justify = 'center' }) {
  return (
    <Box
      display="flex"
      justifyContent={justify}
      alignItems="center"
      width="100%"
      minHeight={200}
      height={height}
    >
      <CircularProgress />
    </Box>
  );
}

import React, {
  createContext,
  FC,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import ConfirmationDialog from 'view/components/ConfirmationDialog';

interface DialogConfig {
  text?: string;
  content?: ReactElement;
  onConfirm?: (isVisible?: boolean) => void;
  onDismiss?: (isVisible?: boolean) => void;
  isDialogVisible: boolean;
  confirmText?: string;
  type?: 'danger' | 'success';
  color?: 'primary' | 'error';
}

const ConfirmationDialogContext = createContext<{
  openDialog: (options: Omit<DialogConfig, 'isDialogVisible'>) => void;
}>({
  openDialog: () => {},
});

interface ConfirmationDialogProviderProps {
  children: ReactElement;
}

const ConfirmationDialogProvider: FC<ConfirmationDialogProviderProps> = ({
  children,
}) => {
  const [dialogConfig, setDialogConfig] = useState<DialogConfig>({
    text: '',
    isDialogVisible: false,
  });

  const openDialog = useCallback(
    (options: Omit<DialogConfig, 'isDialogVisible'>) =>
      setDialogConfig({ ...options, isDialogVisible: true }),
    [],
  );

  const resetDialog = () => {
    setDialogConfig({
      isDialogVisible: false,
      text: '',
    });
  };

  const onConfirm = () => {
    resetDialog();
    dialogConfig.onConfirm?.(true);
  };

  const onDismiss = () => {
    dialogConfig.onDismiss?.(false);
    resetDialog();
  };

  const confirmDialogContextValue = useMemo(
    () => ({ openDialog }),
    [openDialog],
  );

  return (
    <ConfirmationDialogContext.Provider value={confirmDialogContextValue}>
      <ConfirmationDialog
        open={dialogConfig.isDialogVisible}
        text={dialogConfig.text || ''}
        onConfirm={onConfirm}
        onClose={onDismiss}
        children={dialogConfig.content}
        confirmText={dialogConfig.confirmText}
        type={dialogConfig.type}
        color={dialogConfig.color}
      />
      {children}
    </ConfirmationDialogContext.Provider>
  );
};

const useConfirmationDialog = () => {
  return useContext(ConfirmationDialogContext);
};

export { ConfirmationDialogProvider, useConfirmationDialog };

import React, { FC, useCallback, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import deepCopy from 'helpers/deepCopy';
import { useTranslation } from 'react-i18next';
import { FiltersData } from 'types/filters';
import Chip from 'view/components/Chip';
import Select from 'view/components/Select';
import EraserIcon from 'view/icons/Eraser';
import { black87 } from 'view/theme/colors';

import { ViewSimpleStoryDrsSortMenu } from './ViewSimpleStoryDrsSortMenu';

const renderFiltersValue = (activeFilters: number, t: any) => {
  if (!activeFilters) {
    return null;
  }
  return (
    <Box display="flex" sx={{ alignItems: 'center' }}>
      <Typography variant="body2" color={black87}>
        {t('filtering.applied') + ': '}
      </Typography>
      <Chip
        label={activeFilters}
        color="primary"
        size="small"
        sx={{ borderRadius: '1rem', margin: 0 }}
      />
    </Box>
  );
};

interface ViewSimpleStoryDrsSortProps {
  onSetLanguages: (language: string[]) => void;
  onSetStatuses: (status: string[]) => void;
}

const ViewSimpleStoryDrsSort: FC<ViewSimpleStoryDrsSortProps> = ({
  onSetLanguages,
  onSetStatuses,
}) => {
  const { t } = useTranslation();
  const languagesData: FiltersData = useMemo(
    () => ({
      en: { value: 'en', label: t('ENG'), checked: false },
      ja: { value: 'ja', label: t('JPN'), checked: false },
      es: { value: 'es', label: t('SPA'), checked: false },
    }),
    [t],
  );

  const statusesData: FiltersData = useMemo(
    () => ({
      submited: {
        value: 'SUBMITED',
        label: t('storyStatus.submitted'),
        checked: false,
      },
      published: {
        value: 'PUBLISHED',
        label: t('storyStatus.published'),
        checked: false,
      },
      rejected: {
        value: 'REJECTED',
        label: t('storyStatus.rejected'),
        checked: false,
      },
      draft: { value: 'DRAFT', label: t('storyStatus.draft'), checked: false },
    }),
    [t],
  );

  const [languages, setLanguages] = useState<FiltersData>(
    deepCopy(languagesData),
  );

  const [statuses, setStatuses] = useState<FiltersData>(deepCopy(statusesData));

  const [isOpened, setIsOpened] = useState(false);

  const activeFilters = useMemo(() => {
    const activeLanguages = Object.keys(languages).filter(
      (option) => languages[option].checked,
    );
    const activeStatuses = Object.keys(statuses).filter(
      (option) => statuses[option].checked,
    );
    return activeLanguages.length + activeStatuses.length;
  }, [languages, statuses]);

  const handleOpen = () => {
    setIsOpened(true);
  };

  const handleClose = () => {
    setIsOpened(false);
  };
  const languagesRender = Object.keys(languages);

  const handleClickLanguage = (optionKey: string) => {
    setLanguages((prev) => {
      prev[optionKey].checked = !prev[optionKey].checked;
      return deepCopy(prev);
    });
  };

  const renderStatuses = Object.keys(statuses);
  const handleClickStatus = (optionKey: string) => {
    setStatuses((prev) => {
      prev[optionKey].checked = !prev[optionKey].checked;
      return deepCopy(prev);
    });
  };

  const handleClear = useCallback(() => {
    setLanguages(deepCopy(languagesData));
    setStatuses(deepCopy(statusesData));
  }, [languagesData, statusesData]);

  const handleApply = useCallback(() => {
    onSetLanguages(
      Object.keys(languages).filter((option) => languages[option].checked),
    );
    onSetStatuses(
      Object.keys(statuses)
        .filter((option) => statuses[option].checked)
        .map((s) => s.toUpperCase()),
    );
    setIsOpened(false);
  }, [languages, onSetLanguages, onSetStatuses, statuses]);

  const handleClearAndSearch = useCallback(() => {
    handleClear();
    onSetLanguages([]);
    onSetStatuses([]);
  }, [handleClear, onSetLanguages, onSetStatuses]);

  return (
    <Box
      width="13.5rem"
      sx={{
        '.MuiInputBase-adornedStart': {
          padding: '0.25rem',
        },
        position: 'relative',
      }}
    >
      <Select
        fullWidth
        open={isOpened}
        onOpen={handleOpen}
        onClose={handleClose}
        label={t('filtering.filters') || ''}
        value={activeFilters ? t('filtering.filters')! : ''}
        renderValue={() => renderFiltersValue(activeFilters, t)}
        id="filters-drs-select"
        startAdornment={
          activeFilters ? (
            <IconButton
              sx={{
                margin: '0 0.25rem 0 0',
                visibility: !activeFilters ? 'hidden' : 'visible',
              }}
              size="small"
              onClick={handleClearAndSearch}
            >
              <EraserIcon />
            </IconButton>
          ) : null
        }
        menu={
          <ViewSimpleStoryDrsSortMenu
            handleClear={handleClear}
            handleClickLanguage={handleClickLanguage}
            handleClickStatus={handleClickStatus}
            languagesRender={languagesRender}
            languages={languages}
            renderStatuses={renderStatuses}
            statuses={statuses}
            handleApply={handleApply}
          />
        }
      />
    </Box>
  );
};

export default ViewSimpleStoryDrsSort;

import React from 'react';

import {
  Tooltip as MUITooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import { styled } from '@mui/system';

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MUITooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#2c2c2c',
    color: 'white',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    padding: 16,
    maxWidth: 313,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '21px',
    position: 'relative',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#2c2c2c',
  },
  [`& .content-container`]: {
    maxHeight: 116,
    overflowY: 'scroll',
  },
}));

import { request } from '../request';
import { URL } from '../url';

import { generateParams } from './api-stories.utils';
import { CreateDeviceReadyStoryParams } from './stories.types';

import type {
  ApiGetListParams,
  LibraryStats,
  PaginatedResponse,
} from '../types';
import type { DeviceReadyStory } from 'types/stories';

export const getLibraryStats = async (): Promise<{
  deviceReadyStories: LibraryStats;
}> => {
  const { data } = await request({
    url: URL.DEVICE_READY_STORIES_LIBRARY_STATS,
  });
  return data;
};

export const getPublishedDeviceReadyStories = async (
  params: ApiGetListParams,
  id?: string,
): Promise<PaginatedResponse<DeviceReadyStory>> => {
  const { data } = await request({
    url:
      URL.DEVICE_PUBLISHED_READY_STORIES +
      (id ? `?originalBasicStoryId=${id}` : ''),
    params: generateParams(params),
  });
  return data;
};

export const searchDeviceReadyStories = async (
  params: ApiGetListParams,
): Promise<PaginatedResponse<DeviceReadyStory>> => {
  const { data } = await request({
    url: URL.DEVICE_READY_STORIES_SEARCH,
    params: generateParams(params),
  });
  return data;
};

export const createDeviceReadyStory = async (
  params: CreateDeviceReadyStoryParams,
) =>
  request({
    url: URL.DEVICE_READY_STORIES,
    method: 'POST',
    data: params,
  });

export const submitDeviceReadyStory = async (id: string) =>
  request({
    url: URL.DEVICE_READY_STORIES + `/${id}/submit`,
    method: 'PATCH',
  });

export const updateDeviceReadyStory = async (
  id: string,
  params: Omit<
    CreateDeviceReadyStoryParams,
    'preferredDeviceReadyStoryId' | 'originalBasicStoryId' | 'contentVersion'
  >,
) =>
  request({
    url: URL.DEVICE_READY_STORIES + `/${id}`,
    method: 'patch',
    data: params,
  });

export const getDeviceReadyStoryById = async (
  id: string,
): Promise<DeviceReadyStory> => {
  const { data } = await request({
    url: URL.DEVICE_READY_STORIES + `/${id}`,
  });
  return data;
};

export const deleteDeviceReadyStory = async (id: string): Promise<void> =>
  request({
    url: URL.DEVICE_READY_STORIES + `/${id}`,
    method: 'delete',
  });

export const rejectDeviceReadyStory = async (id: string): Promise<void> =>
  request({
    url: URL.MODERATION_DEVICE_READY_STORIES + `/${id}/reject`,
    method: 'put',
    data: {},
  });

export const publishDeviceReadyStory = async (id: string): Promise<void> =>
  request({
    url: URL.MODERATION_DEVICE_READY_STORIES + `/${id}/approve`,
    method: 'put',
  });

export const copyDeviceReadyStory = async (
  id: string,
): Promise<DeviceReadyStory> => {
  const { data } = await request({
    url: URL.DEVICE_READY_STORIES + `/${id}/clone`,
    method: 'post',
  });
  return data;
};

export const importDeviceReadyStory = async (
  file: File,
  isPreImport?: boolean,
): Promise<DeviceReadyStory> => {
  const formData = new FormData();
  formData.append('archive', file);
  const { data } = await request({
    url: URL.DEVICE_READY_STORIES + (isPreImport ? '/pre-import' : '/import'),
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
};

export const syncDeviceReadyStoryToRdmp = async (id: string) =>
  request({
    url: URL.DEVICE_READY_STORIES + `/${id}/sync-to-rdmp`,
    method: 'post',
  });

export default function NoSearchResults() {
  return (
    <svg
      width="117"
      height="99"
      viewBox="0 0 117 99"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48.4313 40.2051L29.1212 7.05937C28.3604 5.75358 26.9726 4.94238 25.4994 4.94238C24.0261 4.94238 22.6383 5.75358 21.8775 7.05946L2.56737 40.2051C1.78113 41.5548 1.75787 43.2457 2.50659 44.618C3.2555 45.9903 4.66652 46.8428 6.18917 46.8428H44.8095C46.3322 46.8428 47.7433 45.9903 48.4922 44.6179C49.2409 43.2455 49.2177 41.5546 48.4313 40.2051ZM45.8234 43.0581C45.6172 43.4358 45.2287 43.6705 44.8095 43.6705H6.18917C5.76998 43.6705 5.3815 43.4359 5.17545 43.0581C4.9693 42.6803 4.97574 42.2148 5.19209 41.8433L24.5024 8.69756C24.7118 8.33812 25.0939 8.11476 25.4994 8.11476C25.9049 8.11476 26.287 8.33812 26.4964 8.69756L45.8065 41.8433C46.0231 42.2149 46.0295 42.6803 45.8234 43.0581Z"
        fill="#B5B5B5"
      />
      <path
        d="M25.5133 17.9941C24.3474 17.9941 23.4375 18.6417 23.4375 19.7896C23.4375 23.2919 23.8355 28.3247 23.8355 31.8271C23.8356 32.7394 24.6035 33.122 25.5134 33.122C26.1958 33.122 27.1626 32.7394 27.1626 31.8271C27.1626 28.3248 27.5607 23.292 27.5607 19.7896C27.5607 18.6417 26.6224 17.9941 25.5133 17.9941Z"
        fill="#B5B5B5"
      />
      <path
        d="M25.5451 35.1504C24.294 35.1504 23.3555 36.1805 23.3555 37.4167C23.3555 38.6234 24.2939 39.683 25.5451 39.683C26.711 39.683 27.7063 38.6234 27.7063 37.4167C27.7063 36.1805 26.7109 35.1504 25.5451 35.1504Z"
        fill="#B5B5B5"
      />
      <path
        d="M66.5294 3.0049C66.4908 2.48744 66.6594 1.97583 66.998 1.58264C67.3366 1.18945 67.8176 0.946875 68.335 0.908282C68.8525 0.869688 69.3641 1.03824 69.7573 1.37685C70.1505 1.71546 70.393 2.1964 70.4316 2.71387L68.4805 2.85938L66.5294 3.0049Z"
        fill="#4B4B4B"
      />
      <path
        d="M103.656 2.70021C103.697 2.18293 103.942 1.70315 104.337 1.36641C104.731 1.02967 105.244 0.863548 105.761 0.904598C106.278 0.945648 106.758 1.1905 107.095 1.5853C107.432 1.98009 107.598 2.49249 107.557 3.00977L105.606 2.85499L103.656 2.70021Z"
        fill="#4B4B4B"
      />
      <path
        d="M85.1406 12.9785H89.0537V32.5437H85.1406V12.9785Z"
        fill="#B5B5B5"
      />
      <path
        d="M78.7832 9.55469H94.9245L94.4354 13.4677H79.2723L78.7832 9.55469Z"
        fill="#B5B5B5"
      />
      <path
        d="M69 52.5977H105.685Lnan nanL105.685 52.5977C105.685 55.0289 103.714 56.9998 101.283 56.9998H73.4022C70.9709 56.9998 69 55.0289 69 52.5977Lnan nanL69 52.5977Z"
        fill="#B5B5B5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M105.195 52.6149V50.6415C105.195 40.6464 97.0927 32.5437 87.0976 32.5437C77.1024 32.5437 68.9997 40.6464 68.9997 50.6415V52.6149C68.9997 54.4966 70.5251 56.0219 72.4068 56.0219H101.788C103.67 56.0219 105.195 54.4966 105.195 52.6149ZM87.0976 31.5654C76.5621 31.5654 68.0215 40.1061 68.0215 50.6415V52.6149C68.0215 55.0368 69.9849 57.0002 72.4068 57.0002H101.788C104.21 57.0002 106.174 55.0368 106.174 52.6149V50.6415C106.174 40.1061 97.633 31.5654 87.0976 31.5654Z"
        fill="#4B4B4B"
      />
      <path
        d="M76.8257 42.3259C76.8257 42.8662 76.3877 43.3042 75.8474 43.3042C75.3071 43.3042 74.8691 42.8662 74.8691 42.3259C74.8691 41.7856 75.3071 41.3477 75.8474 41.3477C76.3877 41.3477 76.8257 41.7856 76.8257 42.3259Z"
        fill="#9D9D9D"
      />
      <path
        d="M96.8803 42.3259C96.8803 42.8662 96.4424 43.3042 95.9021 43.3042C95.3618 43.3042 94.9238 42.8662 94.9238 42.3259C94.9238 41.7856 95.3618 41.3477 95.9021 41.3477C96.4424 41.3477 96.8803 41.7856 96.8803 42.3259Z"
        fill="#9D9D9D"
      />
      <path
        d="M76.8257 44.7712C76.8257 45.3115 76.3877 45.7495 75.8474 45.7495C75.3071 45.7495 74.8691 45.3115 74.8691 44.7712C74.8691 44.231 75.3071 43.793 75.8474 43.793C76.3877 43.793 76.8257 44.231 76.8257 44.7712Z"
        fill="#9D9D9D"
      />
      <path
        d="M96.8803 44.7712C96.8803 45.3115 96.4424 45.7495 95.9021 45.7495C95.3618 45.7495 94.9238 45.3115 94.9238 44.7712C94.9238 44.231 95.3618 43.793 95.9021 43.793C96.4424 43.793 96.8803 44.231 96.8803 44.7712Z"
        fill="#9D9D9D"
      />
      <path
        d="M79.271 44.7712C79.271 45.3115 78.833 45.7495 78.2927 45.7495C77.7524 45.7495 77.3145 45.3115 77.3145 44.7712C77.3145 44.231 77.7524 43.793 78.2927 43.793C78.833 43.793 79.271 44.231 79.271 44.7712Z"
        fill="#9D9D9D"
      />
      <path
        d="M92.9663 44.7712C92.9663 45.3115 92.5283 45.7495 91.988 45.7495C91.4477 45.7495 91.0098 45.3115 91.0098 44.7712C91.0098 44.231 91.4477 43.793 91.988 43.793C92.5283 43.793 92.9663 44.231 92.9663 44.7712Z"
        fill="#9D9D9D"
      />
      <path
        d="M83.185 47.2175C83.185 47.7578 82.7471 48.1958 82.2068 48.1958C81.6665 48.1958 81.2285 47.7578 81.2285 47.2175C81.2285 46.6772 81.6665 46.2393 82.2068 46.2393C82.7471 46.2393 83.185 46.6772 83.185 47.2175Z"
        fill="#9D9D9D"
      />
      <path
        d="M85.6303 47.2175C85.6303 47.7578 85.1924 48.1958 84.6521 48.1958C84.1118 48.1958 83.6738 47.7578 83.6738 47.2175C83.6738 46.6772 84.1118 46.2393 84.6521 46.2393C85.1924 46.2393 85.6303 46.6772 85.6303 47.2175Z"
        fill="#9D9D9D"
      />
      <path
        d="M88.0757 47.2175C88.0757 47.7578 87.6377 48.1958 87.0974 48.1958C86.5571 48.1958 86.1191 47.7578 86.1191 47.2175C86.1191 46.6772 86.5571 46.2393 87.0974 46.2393C87.6377 46.2393 88.0757 46.6772 88.0757 47.2175Z"
        fill="#9D9D9D"
      />
      <path
        d="M90.521 47.2175C90.521 47.7578 90.083 48.1958 89.5427 48.1958C89.0024 48.1958 88.5645 47.7578 88.5645 47.2175C88.5645 46.6772 89.0024 46.2393 89.5427 46.2393C90.083 46.2393 90.521 46.6772 90.521 47.2175Z"
        fill="#9D9D9D"
      />
      <path
        d="M92.9663 47.2175C92.9663 47.7578 92.5283 48.1958 91.988 48.1958C91.4477 48.1958 91.0098 47.7578 91.0098 47.2175C91.0098 46.6772 91.4477 46.2393 91.988 46.2393C92.5283 46.2393 92.9663 46.6772 92.9663 47.2175Z"
        fill="#9D9D9D"
      />
      <path
        d="M99.3257 44.7712C99.3257 45.3115 98.8877 45.7495 98.3474 45.7495C97.8071 45.7495 97.3691 45.3115 97.3691 44.7712C97.3691 44.231 97.8071 43.793 98.3474 43.793C98.8877 43.793 99.3257 44.231 99.3257 44.7712Z"
        fill="#9D9D9D"
      />
      <path
        d="M79.271 42.3259C79.271 42.8662 78.833 43.3042 78.2927 43.3042C77.7524 43.3042 77.3145 42.8662 77.3145 42.3259C77.3145 41.7856 77.7524 41.3477 78.2927 41.3477C78.833 41.3477 79.271 41.7856 79.271 42.3259Z"
        fill="#9D9D9D"
      />
      <path
        d="M99.3257 42.3259C99.3257 42.8662 98.8877 43.3042 98.3474 43.3042C97.8071 43.3042 97.3691 42.8662 97.3691 42.3259C97.3691 41.7856 97.8071 41.3477 98.3474 41.3477C98.8877 41.3477 99.3257 41.7856 99.3257 42.3259Z"
        fill="#9D9D9D"
      />
      <path
        d="M83.185 44.7712C83.185 45.3115 82.7471 45.7495 82.2068 45.7495C81.6665 45.7495 81.2285 45.3115 81.2285 44.7712C81.2285 44.231 81.6665 43.793 82.2068 43.793C82.7471 43.793 83.185 44.231 83.185 44.7712Z"
        fill="#9D9D9D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.2285 44.7712C81.2285 44.231 81.6665 43.793 82.2068 43.793C82.7471 43.793 83.185 44.231 83.185 44.7712V46.2386H91.0111V44.7712C91.0111 44.231 91.4491 43.793 91.9894 43.793C92.5297 43.793 92.9676 44.231 92.9676 44.7712V46.2386V47.2169C92.9676 47.7572 92.5297 48.1951 91.9894 48.1951H91.0111H83.185H82.2068C82.173 48.1951 82.1396 48.1934 82.1068 48.1901C81.6135 48.14 81.2285 47.7234 81.2285 47.2169V46.2386V44.7712Z"
        fill="#B5B5B5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.0414 3.4997L64.0663 4.16908C62.9575 4.25178 62.1846 4.31018 61.5865 4.40423C60.9997 4.49649 60.6672 4.61171 60.4201 4.76178C59.8903 5.08344 59.476 5.56455 59.2365 6.13616C59.1247 6.40284 59.0601 6.74874 59.0559 7.3427C59.0516 7.94813 59.1085 8.72113 59.1912 9.82995L59.8606 18.805C59.9433 19.9138 60.0017 20.6867 60.0957 21.2848C60.188 21.8716 60.3032 22.2041 60.4533 22.4512C60.7749 22.981 61.256 23.3953 61.8277 23.6348C62.0943 23.7466 62.4402 23.8112 63.0342 23.8154C63.6396 23.8197 64.4126 23.7628 65.5214 23.6801L74.4965 23.0107C75.6053 22.928 76.3782 22.8696 76.9763 22.7756C77.5631 22.6833 77.8956 22.5681 78.1427 22.418C78.6725 22.0964 79.0868 21.6152 79.3263 21.0436C79.4381 20.777 79.5027 20.4311 79.5069 19.8371C79.5112 19.2317 79.4543 18.4587 79.3716 17.3499L78.7022 8.37478C78.6195 7.26597 78.5611 6.49308 78.4671 5.89499C78.3748 5.30821 78.2596 4.97573 78.1095 4.72858C77.7879 4.19882 77.3067 3.78449 76.7351 3.54498C76.4685 3.43323 76.1226 3.36861 75.5286 3.3644C74.9232 3.36012 74.1502 3.41701 73.0414 3.4997ZM58.3342 5.7581C57.9712 6.62455 58.0527 7.71727 58.2157 9.9027L58.885 18.8778C59.048 21.0632 59.1295 22.1559 59.6171 22.9589C60.046 23.6653 60.6874 24.2177 61.4496 24.5371C62.316 24.9001 63.4088 24.8186 65.5942 24.6556L74.5693 23.9863C76.7547 23.8233 77.8474 23.7418 78.6504 23.2542C79.3568 22.8253 79.9092 22.1838 80.2286 21.4217C80.5916 20.5553 80.5101 19.4625 80.3471 17.2771L79.6778 8.30203C79.5148 6.11659 79.4333 5.02387 78.9457 4.22086C78.5168 3.51452 77.8753 2.96208 77.1132 2.64272C76.2468 2.27966 75.154 2.36116 72.9686 2.52415L63.9935 3.19353C61.8081 3.35652 60.7154 3.43802 59.9124 3.92559C59.206 4.35447 58.6536 4.99595 58.3342 5.7581Z"
        fill="#4B4B4B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M110.015 4.18638L101.043 3.47439C99.9343 3.38643 99.1616 3.32587 98.5562 3.32728C97.9622 3.32867 97.616 3.39165 97.3488 3.50213C96.7761 3.73893 96.293 4.15097 95.9688 4.67919C95.8176 4.92563 95.7008 5.25756 95.6057 5.84389C95.5088 6.44153 95.4468 7.21413 95.3588 8.32254L94.6468 17.2943C94.5589 18.4027 94.4983 19.1755 94.4997 19.7809C94.5011 20.3749 94.5641 20.7211 94.6746 20.9883C94.9114 21.561 95.3234 22.0441 95.8516 22.3683C96.0981 22.5195 96.43 22.6363 97.0163 22.7314C97.614 22.8282 98.3866 22.8903 99.495 22.9783L108.467 23.6903C109.575 23.7782 110.348 23.8388 110.953 23.8374C111.547 23.836 111.894 23.773 112.161 23.6625C112.733 23.4257 113.217 23.0137 113.541 22.4855C113.692 22.239 113.809 21.9071 113.904 21.3208C114.001 20.7231 114.063 19.9505 114.151 18.8421L114.863 9.87033C114.951 8.76192 115.011 7.9892 115.01 7.38376C115.008 6.78977 114.945 6.44358 114.835 6.17637C114.598 5.60363 114.186 5.12055 113.658 4.79638C113.411 4.64514 113.08 4.52835 112.493 4.4333C111.896 4.33641 111.123 4.27434 110.015 4.18638ZM95.135 4.16751C94.6437 4.96819 94.557 6.06051 94.3836 8.24515L93.6716 17.2169C93.4983 19.4016 93.4116 20.4939 93.7705 21.3621C94.0863 22.1257 94.6356 22.7698 95.3399 23.202C96.1406 23.6934 97.2329 23.7801 99.4176 23.9535L108.389 24.6655C110.574 24.8388 111.666 24.9255 112.534 24.5666C113.298 24.2508 113.942 23.7014 114.374 22.9971C114.866 22.1965 114.953 21.1041 115.126 18.9195L115.838 9.94772C116.011 7.76308 116.098 6.67076 115.739 5.80259C115.423 5.03894 114.874 4.39484 114.17 3.96261C113.369 3.47123 112.277 3.38455 110.092 3.21118L101.12 2.4992C98.9355 2.32583 97.8432 2.23915 96.975 2.59809C96.2114 2.91382 95.5673 3.46321 95.135 4.16751Z"
        fill="#4B4B4B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.4637 16.0288C70.8107 15.9283 71.8212 14.755 71.7207 13.408C71.6203 12.0611 70.4469 11.0506 69.0999 11.151C67.753 11.2515 66.7425 12.4249 66.843 13.7718C66.9434 15.1188 68.1168 16.1292 69.4637 16.0288ZM69.6093 17.9799C72.0338 17.7991 73.8526 15.687 73.6718 13.2625C73.491 10.838 71.379 9.01911 68.9544 9.19993C66.5299 9.38076 64.711 11.4928 64.8919 13.9173C65.0727 16.3418 67.1847 18.1607 69.6093 17.9799Z"
        fill="#4B4B4B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M104.561 16.0203C105.908 16.1272 107.086 15.1223 107.193 13.7758C107.3 12.4293 106.295 11.2512 104.948 11.1443C103.602 11.0375 102.424 12.0424 102.317 13.3889C102.21 14.7353 103.215 15.9135 104.561 16.0203ZM104.407 17.9707C106.83 18.163 108.951 16.3542 109.143 13.9306C109.336 11.5069 107.527 9.38629 105.103 9.19396C102.68 9.00162 100.559 10.8104 100.367 13.2341C100.174 15.6577 101.983 17.7784 104.407 17.9707Z"
        fill="#4B4B4B"
      />
      <path
        d="M54.6667 82.3333C69.3943 82.3333 81.3333 70.3943 81.3333 55.6667C81.3333 40.9391 69.3943 29 54.6667 29C39.9391 29 28 40.9391 28 55.6667C28 70.3943 39.9391 82.3333 54.6667 82.3333Z"
        fill="white"
        stroke="#757575"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88 89L73.5 74.5"
        stroke="#757575"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

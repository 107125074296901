import { useCallback, useEffect, useMemo, useState } from 'react';

import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { useLoader } from '../../contexts/loader';

const useFetch = <T>(
  cb: () => Promise<T>,
  initData: T | null = null,
  deps?: unknown[],
) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const memoisedDeps = useMemo(() => (deps?.length ? deps : []), [deps]);
  const { onHideLoader, onShowLoader } = useLoader();
  const [fetchLoading, setFetchLoading] = useState(true);
  const [data, setData] = useState<T | null>(initData);
  const [error, setError] = useState<AxiosError | null>(null);
  const [isFetched, setIsFetched] = useState(false);

  const handleInvalidateData = useCallback(() => {
    setData(null);
    setError(null);
    setIsFetched(false);
  }, []);

  const request = useCallback(async () => {
    try {
      setFetchLoading(true);
      onShowLoader();
      const result = await cb();
      flushSync(() => {
        setData(result);
      });
      setIsFetched(true);
    } catch (err) {
      const errors = err as AxiosError;
      setError(errors);
      enqueueSnackbar(errors?.message || t('Something went wrong.'), {
        variant: 'error',
      });
    } finally {
      onHideLoader();
      setFetchLoading(false);
    }
  }, [cb, enqueueSnackbar, onHideLoader, onShowLoader, t]);

  useEffect(() => {
    request();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request, ...memoisedDeps]);

  const memoisedData = useMemo(() => data, [data]);
  const memoisedError = useMemo(() => error, [error]);

  return useMemo(
    () => ({
      data: memoisedData,
      error: memoisedError,
      onRefetch: request,
      fetchLoading,
      isFetched,
      handleInvalidateData,
    }),
    [
      fetchLoading,
      handleInvalidateData,
      isFetched,
      memoisedData,
      memoisedError,
      request,
    ],
  );
};

export default useFetch;

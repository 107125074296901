import React, { ReactElement } from 'react';

import { Badge } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { black54 } from 'view/theme/colors';

const StylePageHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.5rem 0 3rem 0;
  flex-wrap: wrap;

  .MuiBadge-badge {
    font-size: 1rem;
    color: ${black54};
  }
`;

type PageHeaderProps = {
  title: string;
  badgeContent?: string | number;
  children?: ReactElement;
};

const PageHeader = ({ title, children, badgeContent }: PageHeaderProps) => (
  <StylePageHeader>
    <Box mr={2}>
      <Badge badgeContent={badgeContent} color="default">
        <Typography variant="h1" mr={1}>
          {title}
        </Typography>
      </Badge>
    </Box>
    {children}
  </StylePageHeader>
);

export default PageHeader;

import { FC } from 'react';

import { ROLES_NAMES } from 'types/users';

import useRole from './useRole';

export interface IRoleProps {
  children: React.ReactNode;
  allowedRoles: ROLES_NAMES[];
  creatorId?: string;
}

const Role: FC<IRoleProps> = ({ children, allowedRoles = [], creatorId }) => {
  const isAllowed = useRole({ allowedRoles, creatorId });
  if (!isAllowed) return null;

  return <>{children}</>;
};

export default Role;

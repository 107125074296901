import { ReactNode } from 'react';

import { FormHelperText, InputLabel, Select } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/system';
import ChevronDownIcon from 'view/icons/ChevronDown';
import { black40 } from 'view/theme/colors';

const StyledInputLabel = styled(InputLabel)(
  () => `
  &.fullHeight {
    top: 0.175rem;
  }
`,
);
const StyledSelect = styled(Select)`
  border-radius: 0.5rem;
  height: 3rem;
  min-width: 10rem;

  &.fullHeight {
    height: 100%;
  }
  .MuiSelect-icon {
    width: 1.5rem;
    height: 1.5rem;
    top: calc(50% - 0.75em);
  }

  .MuiSelect-iconOpen {
    top: calc(50% - 0.75em);
  }
`;

const MenuProps = {
  PaperProps: {
    style: {
      border: '1px solid rgba(0, 0, 0, 0.08)',
      boxShadow: '0 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
      borderRadius: '0.5rem',
    },
  },
};

type DropdownSelectProps = {
  id: string;
  label: string;
  fullWidth?: boolean;
  disabled?: boolean;
  placeholder?: string;
  multiple?: boolean;
  value?: string;
  className?: string;
  menu: ReactNode;
  open?: boolean;
  onOpen?: (event: object) => void;
  onClick?: (event: object) => void;
  startAdornment?: ReactNode;
  onChange?: (event: any) => void;
  renderValue?: () => ReactNode;
  onClose?: () => void;
  error?: boolean;
  helperText?: string;
};

export default function DropdownSelect({
  label,
  disabled,
  fullWidth,
  multiple,
  menu,
  open,
  onOpen,
  onClick,
  onClose,
  value,
  onChange,
  renderValue,
  startAdornment,
  id,
  placeholder,
  className,
  error,
  helperText,
}: DropdownSelectProps) {
  return (
    <FormControl fullWidth={fullWidth} error={error}>
      {label && (
        <StyledInputLabel
          className={className}
          sx={{
            fontWeight: 500,
            fontSize: '0.875rem',
            lineHeight: '1rem',
            color: black40,
          }}
          htmlFor={id}
        >
          {label}
        </StyledInputLabel>
      )}
      <StyledSelect
        id={id}
        fullWidth={fullWidth}
        disabled={disabled}
        placeholder={placeholder}
        multiple={multiple}
        displayEmpty
        className={className}
        value={value}
        open={open}
        onClick={onClick}
        onOpen={onOpen}
        label={label}
        onChange={onChange}
        onClose={onClose}
        MenuProps={MenuProps}
        startAdornment={startAdornment}
        IconComponent={(props: any) => (
          <Box {...props} mr={1}>
            <ChevronDownIcon />
          </Box>
        )}
        renderValue={renderValue}
      >
        {menu}
      </StyledSelect>
      {!!helperText && (
        <Box position="absolute" bottom={-20} left={0}>
          <FormHelperText>{helperText}</FormHelperText>
        </Box>
      )}
    </FormControl>
  );
}

import React, { FC, useCallback } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { FiltersData } from 'types/filters';
import Button from 'view/components/Button';
import { StyledFiltersContainer } from 'view/components/TagFilters/styled';
import TrashIcon from 'view/icons/Trash';
import { black, black16, black54, black87, purple } from 'view/theme/colors';

interface ViewSimpleStoryDrsSortMenuProps {
  handleClear: () => void;
  languagesRender: string[];
  languages: FiltersData;
  statuses: FiltersData;
  handleClickLanguage: (language: string) => void;
  handleClickStatus: (status: string) => void;
  renderStatuses: string[];
  handleApply: () => void;
}

export const ViewSimpleStoryDrsSortMenu: FC<
  ViewSimpleStoryDrsSortMenuProps
> = ({
  handleClear,
  languagesRender,
  languages,
  renderStatuses,
  statuses,
  handleClickLanguage,
  handleClickStatus,
  handleApply,
}) => {
  const { t } = useTranslation();

  const listRender = useCallback(
    (
      renderData: string[],
      data: FiltersData,
      handleClick: (option: string) => void,
    ) => (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControl
          sx={{ marginBottom: '2rem' }}
          component="fieldset"
          variant="standard"
        >
          <FormGroup
            sx={{
              display: 'flex',
              flexDirection: 'row',
              rowGap: '0.75rem',
            }}
          >
            {renderData.map((option: string, index) => {
              const currentOpt = data[option];
              return (
                <FormControlLabel
                  sx={{ margin: 0, columnGap: '0.75rem' }}
                  key={option}
                  control={
                    <Box>
                      <Button
                        sx={{
                          borderColor: currentOpt.checked ? purple : black16,
                          color: currentOpt.checked ? purple : black87,
                        }}
                        variant="outlined"
                        label={currentOpt.label}
                        onClick={() => handleClick(option)}
                      />
                    </Box>
                  }
                  label={''}
                />
              );
            })}
          </FormGroup>
        </FormControl>
      </Box>
    ),
    [],
  );

  return (
    <StyledFiltersContainer>
      <Box
        mb={2}
        display="flex"
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="subtitle1" sx={{ color: black }}>
          {t('filtering.byLanguage')}:
        </Typography>
        <Button
          label={t('filtering.clearAll')}
          color="inherit"
          className="clear-filters"
          variant="text"
          endIcon={<TrashIcon color={black54} />}
          onClick={handleClear}
        />
      </Box>

      {listRender(languagesRender, languages, handleClickLanguage)}

      <Box
        mb={2}
        display="flex"
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="subtitle1" sx={{ color: black }}>
          {t('filtering.byStatus')}:
        </Typography>
      </Box>
      {listRender(renderStatuses, statuses, handleClickStatus)}
      <Box mb={2}>
        <Button
          type="submit"
          label={t('filtering.applyFilters')}
          withLoader
          fullWidth
          onClick={handleApply}
          // loading={submitting}
        />
      </Box>
    </StyledFiltersContainer>
  );
};

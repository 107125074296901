import { SimpleStory } from 'types/stories';

import apiRequest, { request } from '../request';
import { ApiGetListParams, LibraryStats, PaginatedResponse } from '../types';
import { URL } from '../url';

import { generateParams } from './api-stories.utils';

import type {
  CreateSimpleStoryPayload,
  SimpleStoryPayload,
} from './stories.types';

export const getPublishedStories = async (
  params: ApiGetListParams,
): Promise<PaginatedResponse<SimpleStory>> => {
  const { data } = await request({
    url: URL.SIMPLE_PUBLISHED_STORIES,
    params: generateParams(params),
  });

  return data;
};

export const getPublishedStoriesStats = async (): Promise<
  Record<'all' | 'stories' | 'deviceReadyStories', number>
> => {
  const { data } = await request({
    url: URL.SIMPLE_PUBLISHED_STORIES_STATS,
  });

  return data;
};
export const searchStories = async (
  params: ApiGetListParams,
): Promise<PaginatedResponse<SimpleStory>> => {
  const { data } = await request({
    url: URL.SIMPLE_STORIES,
    params: generateParams(params),
  });

  return data;
};

export const getLibraryStats = async (): Promise<{
  stories: LibraryStats;
}> => {
  const { data } = await request({
    url: URL.SIMPLE_STORIES_LIBRARY_STATS,
    params: {},
  });

  return data;
};

export const createSimpleStory = async (data: CreateSimpleStoryPayload) =>
  apiRequest({
    url: URL.STORIES,
    method: 'post',
    data,
  });

export const updateSimpleStory = async (id: string, data: SimpleStoryPayload) =>
  apiRequest({
    url: URL.STORIES + `/${id}`,
    method: 'patch',
    data,
  });

export const submitStory = async (id: string) =>
  await apiRequest({
    url: URL.STORIES + `/${id}/submit`,
    method: 'patch',
  });

export const publishStory = async (id: string) => {
  return await apiRequest({
    method: 'put',
    url: URL.MODERATION_STORIES + `/${id}/approve`,
  });
};

export const rejectStory = async (id: string) => {
  return await apiRequest({
    method: 'put',
    url: URL.MODERATION_STORIES + `/${id}/reject`,
    data: {},
  });
};

export const deleteStory = async (id: string): Promise<SimpleStory[]> =>
  apiRequest({
    url: URL.STORIES + `/${id}`,
    method: 'delete',
  });

export const getDraftStoryById = async (id: string): Promise<SimpleStory> =>
  apiRequest({
    url: URL.STORIES + `/${id}`,
    method: 'get',
  });

export const searchBookmarkStories = async (
  params: ApiGetListParams,
): Promise<PaginatedResponse<SimpleStory>> => {
  const { data } = await request({
    url: URL.BOOKMARKS_STORIES,
    params: generateParams(params),
  });

  return data;
};

export const addStoryToBookmark = async (id: string) =>
  apiRequest({
    url: URL.BOOKMARKS_STORIES + `/${id}`,
    method: 'post',
  });

export const removeStoryFromBookmark = async (id: string) =>
  apiRequest({
    url: URL.BOOKMARKS_STORIES + `/${id}`,
    method: 'delete',
  });

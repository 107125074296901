import { FC } from 'react';

import { SxProps } from '@mui/material';
import MuiChip from '@mui/material/Chip';
import { styled } from '@mui/system';
import {
  black54,
  green,
  lightGray,
  lightGreen,
  lightPurple,
  lightRed,
  lightYellow,
  purple,
  red,
  yellow,
} from 'view/theme/colors';

export const StyledChip = styled(MuiChip)(
  () => `
  &.MuiChip-root {
    height: 1.75rem;
    border-radius: 0.25rem;
    span {
      font-size: 0.75rem;
      font-weight: 600;
    }
  }

  &.MuiChip-sizeSmall {
  }
  &.MuiChip-sizeSmall {
    font-size: 0.75rem;
  }

  &.MuiChip-filledPrimary {
    color: ${purple};
    background: ${lightPurple};
  }

  &.MuiChip-filledSuccess {
    color: ${green};
    background: ${lightGreen};
  }

  &.MuiChip-filledWarning {
    color: ${yellow};
    background: ${lightYellow};
  }

  &.MuiChip-filledError {
    color: ${red};
    background: ${lightRed};
  }

  &.MuiChip-filledSecondary {
    border-radius: 10px;
    color: ${black54};
    background: ${lightGray};
  }

  &.MuiChip-root.notification {
    width: 26px;
    height: 20px;
    border-radius: 10px;
    span {
      font-size: 0.75rem;
    }
  }
`,
);

interface ChipProps {
  label: string | number | undefined;
  color?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | any;
  size?: 'medium' | 'small';
  sx?: SxProps;
  type?: 'simple' | 'notification';
}

const Chip: FC<ChipProps> = ({ label, color, size, sx, type }) => {
  return (
    <StyledChip
      label={label}
      color={color}
      size={size}
      sx={{ textTransform: 'capitalize', ...sx }}
      className={type}
    />
  );
};

export default Chip;

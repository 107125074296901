import { Tag } from 'types/stories';

import { request } from './request';
import { URL } from './url';

export const getTags = async (): Promise<Tag[]> => {
  const { data } = await request({
    url: URL.TAGS,
    params: {},
  });

  return data;
};

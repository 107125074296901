const generateDescription = (description: string) => {
  const regex = /(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2}.\d{3})Z/;
  const match = description.match(regex);
  if (match) {
    const result = description.match(regex);

    if (result && Array.isArray(result)) {
      const [, datePart, timePart] = result;
      const formattedDate = `${datePart} at ${timePart.slice(0, 5)}`;
      return description.replace(regex, formattedDate);
    }
    return description;
  }

  return description;
};

export default generateDescription;

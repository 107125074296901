import { useCallback, useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

const useUpdateQueryData = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = useMemo(
    () => parseInt(searchParams.get('page') || '1'),
    [searchParams],
  );

  const handleUpdatePage = useCallback(
    (page: number) => {
      const isSearchParamExist = searchParams.has('page');

      if (!page || (page === 1 && !isSearchParamExist)) return;

      setSearchParams((prev) => {
        prev.set('page', page.toString());
        if (page === 1) {
          prev.delete('page');
        }
        return prev;
      });
    },
    [searchParams, setSearchParams],
  );

  return {
    page,
    handleUpdatePage,
  };
};

export default useUpdateQueryData;

import { createTheme } from '@mui/material/styles';

import {
  black100,
  black40,
  black54,
  black87,
  green,
  lightPurple,
  lightRed,
  purple,
  red,
  white,
} from './colors';

const theme = createTheme({
  palette: {
    primary: {
      main: purple,
    },
    error: {
      main: red,
    },
    success: {
      main: green,
    },
  },
  typography: {
    fontFamily: `"Manrope", "Helvetica", "Arial", sans-serif`,
    button: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '24px',
      color: black87,
    },
    h1: {
      fontSize: '2rem',
      fontWeight: 700,
      lineHeight: '32px',
      color: black87,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: '40px',
      color: black87,
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: '30px',
      color: black100,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '24px',
      color: black87,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: '24px',
      color: black54,
    },
    subtitle1: {
      fontSize: '0.75rem',
      fontWeight: 800,
      letterSpacing: 0.2,
      lineHeight: '24px',
      textTransform: 'uppercase',
      color: black54,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-textPrimary': {
            color: black40,
          },
          '&:hover.MuiButton-contained': {
            opacity: 0.8,
          },
          '&.remove-button': {
            backgroundColor: lightRed,
          },
          '&:hover.remove-button': {
            backgroundColor: red,
            color: white,
          },
          '&:hover.remove-button svg path': {
            stroke: white,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          height: 45,
          width: 45,
          color: black54,

          '&:hover': {
            color: purple,
            background: lightPurple,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          height: 28,
          span: {
            fontWeight: 500,
            fontSize: '0.875rem',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          msOverflowStyle: 'none',
        },
        '::-webkit-scrollbar': {
          display: 'none',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          span: {
            right: '-0.7rem',
            top: '0.3rem',
            backgroundColor: 'white',
            color: black54,
            fontSize: '1rem',
          },
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default theme;
export type Theme = typeof theme;

import React, { FC, Suspense } from 'react';

import { AuthProvider } from 'contexts/auth';
import { CacheImagesProvider } from 'contexts/cache-images';
import generateRoute from 'helpers/generateRoute';
import { BrowserRouter as Router, Routes } from 'react-router-dom';
import routesConfig from 'routes/routes.config';
import Loader from 'view/components/Loader';

const routes = generateRoute(routesConfig);
const App: FC = () => {
  return (
    <Suspense fallback={<Loader height="100vh" />}>
      <AuthProvider>
        <CacheImagesProvider>
          <Router>
            <Routes>{routes}</Routes>
          </Router>
        </CacheImagesProvider>
      </AuthProvider>
    </Suspense>
  );
};

export default App;

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en.json';
import translationSPA from './locales/es.json';
import translationJPN from './locales/jpn.json';

export const LANGUAGE_KEY_ENG = 'en-US';
export const LANGUAGE_KEY_JPN = 'jpn';
export const LANGUAGE_KEY_SPA = 'es';

export const resources = {
  [LANGUAGE_KEY_ENG]: { translation: translationEN },
  [LANGUAGE_KEY_JPN]: { translation: translationJPN },
  [LANGUAGE_KEY_SPA]: { translation: translationSPA },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: LANGUAGE_KEY_ENG,
    fallbackLng: LANGUAGE_KEY_ENG,
    interpolation: {
      escapeValue: false, // React already escapes output by default
    },
    detection: {
      order: ['localStorage'], // Use local storage to detect language preference
    },
    debug: false,
  });

export default i18n;

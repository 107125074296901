import { useEffect } from 'react';

const useOnUnmount = (callback: () => void) => {
  useEffect(() => {
    return () => {
      callback();
    };
    // eslint-disable-next-line
  }, []);
};

export default useOnUnmount;

import React, { FC, MouseEvent, useCallback } from 'react';

import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useConfirmationDialog } from 'contexts/confirmation-dialog';
import useFetch from 'hooks/useFetch';
import useFetchListDataWithFilteringAndSorting from 'hooks/useFetchListDataWithFilteringAndSorting';
import useRefetchIfDrsPublishing from 'hooks/useRefetchIfDrsPublishing';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  bulkUnshareDeviceAccess,
  getDevice,
  getSharedDeviceAccess,
  removeDevice,
  unshareDeviceAccess,
} from 'services/api/devices';
import { ApiGetListParams } from 'services/api/types';
import { Device } from 'types/devices';
import { DeviceReadyStory } from 'types/stories';
import BackButton from 'view/components/BackButton';
import Button from 'view/components/Button';
import DeviceReadyStoryCardRow from 'view/components/DeviceReadyStoryCardRow';
import FilterSortBlock from 'view/components/FilterSortBlock/FilterSortBlock';
import MainContainer from 'view/components/MainContainer';
import MainLayout from 'view/components/MainLayout';
import MultiselectCheckboxGroup from 'view/components/MultiselectCheckboxGroup';
import NoSearchResultsWrapper from 'view/components/NoSearchResultsWrapper';
import PageHeader from 'view/components/PageHeader';
import TablePagination from 'view/components/TablePagination';
import TrashIcon from 'view/icons/Trash';
import { lightGray, purple, red } from 'view/theme/colors';

import { DeviceDetailPageSidebar } from './DeviceDetailPageSidebar';

export const DeviceDetailPage: FC = () => {
  const { openDialog } = useConfirmationDialog();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { id = '' } = useParams();

  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  const handleToggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const handleFetchData = useCallback(async () => await getDevice(id), [id]);
  const handleFetchItems = useCallback(
    (params: ApiGetListParams) => {
      return getSharedDeviceAccess(id, params);
    },
    [id],
  );

  const { data } = useFetch<Device<DeviceReadyStory>>(handleFetchData);
  const {
    data: deviceDrs,
    onChangePage,
    onSetTags,
    sorting,
    onSetSorting,
    onSetSearchQuery,
    onRefetch,
    isFilterShown,
    fetchLoading,
    isFiltersApplied,
  } = useFetchListDataWithFilteringAndSorting<DeviceReadyStory>(
    handleFetchItems,
  );

  useRefetchIfDrsPublishing(deviceDrs?.items || [], onRefetch);

  if (!data) return null;

  const items = deviceDrs?.items || [];

  const pageTitle = `${t('devicesListPage.deviceManager')} / ${data.name}`;

  const handleRemoveButchAccess = async (ids: string[]) => {
    await bulkUnshareDeviceAccess(id, ids);
    onRefetch();
  };

  const handleDeleteDevice = async () => {
    try {
      openDialog({
        text: `${t('modal.areYouSure')}`,
        onConfirm: async () => {
          await removeDevice(id);
          navigate(-1);
          enqueueSnackbar(t('notifications.successfullyDeleted') || '', {
            variant: 'success',
          });
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <MainContainer>
      <MultiselectCheckboxGroup>
        <DeviceDetailPageSidebar
          name={'HARU'}
          updatedDate={data.updatedAt}
          open={isSidebarOpen}
          onClose={handleToggleSidebar}
          onRefetch={onRefetch}
          drsId={id}
        />
      </MultiselectCheckboxGroup>
      <MainLayout flexDirection="column">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <PageHeader title={pageTitle || ''}>
              <Typography variant="h1" color={'#a6a6a6'}>
                {`(${t('devicesListPage.sharedContentList')})`}
              </Typography>
            </PageHeader>
            <Box display="flex" alignItems="center">
              <Button
                onClick={handleToggleSidebar}
                variant="stroke"
                label={t('devicesListPage.addDeviceReadyStory')}
                endIcon={
                  <AddBoxOutlinedIcon sx={{ fontSize: '24px !important' }} />
                }
                sx={{
                  mr: 3,
                  backgroundColor: 'rgba(72, 83, 231, 0.08)',
                  color: purple,
                  '&:hover': {
                    backgroundColor: 'rgba(72, 83, 231, 0.18)',
                  },
                }}
              />
              <Button
                onClick={handleDeleteDevice}
                variant="stroke"
                label={t('devicesListPage.removeDevice')}
                endIcon={<TrashIcon />}
                sx={{
                  backgroundColor: 'rgba(235, 85, 85, 0.1)',
                  color: red,
                  '&:hover': {
                    backgroundColor: 'rgba(235, 85, 85, 0.2)',
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
        <BackButton />
      </MainLayout>
      <Divider sx={{ width: '100%' }} />
      {isFilterShown && (
        <MainLayout>
          <FilterSortBlock
            setTags={onSetTags}
            setSearchQuery={onSetSearchQuery}
            sorting={sorting}
            setSorting={onSetSorting}
          />
        </MainLayout>
      )}
      {isFilterShown && <Divider />}
      <MultiselectCheckboxGroup>
        <Box bgcolor={lightGray} width="100%" height="100%" pt={5}>
          <MainLayout
            width="100%"
            height="100%"
            justifyContent="flex-start"
            flexDirection="column"
          >
            {!!items?.length && (
              <Box
                pb={1}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MultiselectCheckboxGroup.MultiselectCheckboxGroupTitle
                  title={t('devicesListPage.deviceReadyStoriesList')}
                />
                <MultiselectCheckboxGroup.MultiselectCheckboxHeadItem
                  label={t('selectAll')!}
                  labelPlacement="start"
                  items={items}
                  keyExtractor="id"
                  onDelete={handleRemoveButchAccess}
                  sx={{ pr: 3 }}
                />
              </Box>
            )}
            <NoSearchResultsWrapper
              isLoading={fetchLoading}
              title={pageTitle}
              isFiltersApplied={isFiltersApplied}
            >
              {items.map((story) => {
                const handleRemoveAccess = async (
                  e: MouseEvent<HTMLElement>,
                ) => {
                  e.stopPropagation();
                  await unshareDeviceAccess(id, story?.id);
                  onRefetch();
                  enqueueSnackbar(
                    t('notifications.successfullyDeleted') || '',
                    {
                      variant: 'success',
                    },
                  );
                };

                return (
                  <DeviceReadyStoryCardRow
                    deviceReadyStory={story}
                    isBaseControlsShown={false}
                    key={story?.id}
                    controls={
                      <Box display="flex" alignItems="center">
                        <Box
                          display="flex"
                          sx={{ cursor: 'pointer' }}
                          onClick={handleRemoveAccess}
                          mr={4}
                          className="controls"
                        >
                          <DeleteOutlineOutlinedIcon sx={{ fill: red }} />
                        </Box>
                        <MultiselectCheckboxGroup.MultiselectCheckboxItem
                          id={story?.id || ''}
                          classNameOnUnchecked="controls"
                        />
                      </Box>
                    }
                  />
                );
              })}
            </NoSearchResultsWrapper>
            <TablePagination {...deviceDrs?.meta} onPageChange={onChangePage} />
          </MainLayout>
        </Box>
      </MultiselectCheckboxGroup>
    </MainContainer>
  );
};

export const purple = '#4853E7';
export const purple_hover = '#f0f1fc';
export const pink = '#EB55E5';
export const darkPurple = '#8E0DF3';
export const lightPurple = 'rgba(72, 83, 231, 0.08)';

export const red = '#EB5555';
export const darkError = '#DE342B';
export const lightRed = 'rgba(235, 85, 85, 0.08)';
export const green = '#51A873';
export const lightGreen = 'rgba(81, 168, 115, 0.08)';
export const darkGreen = '#2B9749';
export const yellow = '#E79E48';
export const lightYellow = 'rgba(231, 158, 72, 0.08)';
export const orange = '#F37B0D';
export const white = '#FFFFFF';
export const gray = '#CACACA';
export const lightGray = '#F6F6F6';
export const grey = '#9F9FA1';
export const unknownStatus = '#636474';
export const codeGreen = '#A8E3BF';
export const blue = '#4853E7';
export const blue2 = '#0D85F3';

export const black = '#000000';
export const black2 = '#F8F8F8';
export const black4 = '#EBEBEB';
export const black8 = 'rgba(26, 26, 26, 0.08)';
export const black16 = '#DADADA';
export const black20 = '#CCCCCC';
export const black29 = '#B5B5B5';
export const black32 = 'rgba(26, 26, 26, 0.32)';
export const black38 = '#9D9D9D';
export const black40 = '#999999';
export const black54 = '#757575';
export const black71 = '#4B4B4B';
export const black87 = '#212121';
export const black100 = '#1A1A1A';

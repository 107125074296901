import { FC, PropsWithChildren } from 'react';

import { BoxProps, SxProps } from '@mui/material';
import Box from '@mui/material/Box';
// import { LAYOUT_SIDE_PADDING, MAX_LAYOUT_WIDTH } from 'helpers/constants';

const MainLayout: FC<PropsWithChildren<BoxProps & SxProps>> = ({
  children,
  ...rest
}) => {
  return (
    <Box
      width="100%"
      justifyContent="center"
      // maxWidth={`${MAX_LAYOUT_WIDTH - LAYOUT_SIDE_PADDING * 2}px`}
      display="flex"
      px="4rem"
      margin="0 auto"
      {...rest}
    >
      {children}
    </Box>
  );
};

export default MainLayout;

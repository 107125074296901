import React, { ReactElement, useEffect, useState } from 'react';

import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/system';
import { lightGray, purple, white } from 'view/theme/colors';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  () => `
  &.MuiToggleButtonGroup-root {
    background: ${lightGray};
    border-radius: 0.5rem;
    height: 3rem;

    .Mui-selected {
      background: ${white};
      box-shadow: 0 0.25rem 0.5rem rgba(26, 26, 26, 0.2);
      border-radius: 0.375rem;
      svg {
        path {
          stroke: ${purple};
        }
      }
    }
  }

  .MuiToggleButtonGroup-grouped {
    margin: 0.375rem;
    border: 0;
  }
`,
);

type ToggleButtonOption = {
  icon: ReactElement | string | ((isSelected: boolean) => ReactElement);
  value: string | number;
  className?: string;
  onClick: () => void;
};

type ToggleButtonProps = {
  options: ToggleButtonOption[];
  size?: 'small' | 'medium' | 'large' | undefined;
  sx?: object;
  initialValue?: number | string;
};

const Toggle = ({
  options,
  sx,
  size = 'small',
  initialValue,
}: ToggleButtonProps) => {
  const [selectedToggle, setSelectedToggle] = useState(
    initialValue ?? options[0]?.value,
  );

  useEffect(() => {
    setSelectedToggle(initialValue ?? options[0].value);
  }, [initialValue, options]);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    value: string,
  ) => {
    if (!value || value === selectedToggle) return;
    setSelectedToggle(value);
  };
  return (
    <StyledToggleButtonGroup
      sx={sx}
      size={size}
      value={selectedToggle}
      exclusive
      orientation="vertical"
      onChange={handleChange}
    >
      {options.map(({ value, icon, className, onClick }) => (
        <ToggleButton
          key={value}
          value={value}
          className={className}
          onClick={onClick}
        >
          {typeof icon === 'function' ? icon(value === selectedToggle) : icon}
        </ToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
};

export default Toggle;

import { FC } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MainLayout from 'view/components/MainLayout';
import NoResults from 'view/icons/NoResults';
import NoSearchResults from 'view/icons/NoSearchResults';
import { black54 } from 'view/theme/colors';

interface ViewSimpleStoryNoContentProps {
  title: string;
  subtitle: string;
  isFiltersApplied?: boolean;
  sx?: any;
}

export const ViewSimpleStoryNoContent: FC<ViewSimpleStoryNoContentProps> = ({
  title,
  subtitle,
  sx,
  isFiltersApplied,
}) => {
  return (
    <MainLayout
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      mt={16}
      {...sx}
    >
      {isFiltersApplied ? <NoSearchResults /> : <NoResults />}
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        mt={3}
        textAlign="center"
      >
        <Typography fontSize={24} fontWeight={700}>
          {title}
        </Typography>
        <Typography color={black54} maxWidth={260} fontWeight={400} mt={1}>
          {subtitle}
        </Typography>
      </Box>
    </MainLayout>
  );
};

import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';

import deepCopy from 'helpers/deepCopy';
import useTags from 'hooks/useTags';
import { useTranslation } from 'react-i18next';
import { FiltersData } from 'types/filters';

import FiltersSelect from '../FiltersSelect';
import TagFilters from '../TagFilters';

interface FilterSortBlockFilterProps {
  setTags: Dispatch<SetStateAction<string[]>>;
}

export const FilterSortBlockFilter: FC<FilterSortBlockFilterProps> = ({
  setTags,
}) => {
  const { t } = useTranslation();

  const {
    tags: filters,
    setTags: setFilters,
    onClearSelectedTags: handleClearSelectedFilters,
    onGetSelectedTags: handleGetSelectedFilters,
  } = useTags();

  const [searchTagsValue, setSearchTagsValue] = useState('');

  const handleApplyTags = useCallback(() => {
    setTags(handleGetSelectedFilters());
  }, [handleGetSelectedFilters, setTags]);

  const handleApplyFilters = useCallback(
    (filters: FiltersData) => setFilters(filters),
    [setFilters],
  );
  const handleClearFilters = useCallback(() => {
    handleClearSelectedFilters();
  }, [handleClearSelectedFilters]);

  const handleOnClickCleanTagButton = useCallback(() => {
    handleClearFilters();
    handleApplyFilters(deepCopy(filters));
    handleApplyTags();
  }, [filters, handleApplyFilters, handleApplyTags, handleClearFilters]);

  return (
    <FiltersSelect
      label={t('filtering.filtersByTags') || ''}
      filters={filters}
      setFilters={setFilters}
      handleOnClickCleanTagButton={handleOnClickCleanTagButton}
      menu={
        <TagFilters
          buttonLabel={t('filtering.applyFilters') || ''}
          data={filters}
          onClear={handleClearFilters}
          setFilters={setFilters}
          onApply={handleApplyTags}
          searchTagsValue={searchTagsValue}
          setSearchTagsValue={setSearchTagsValue}
          placeholder={t('filtering.searchByTagName') || ''}
        />
      }
    />
  );
};

import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  addCommentsForDrs,
  addCommentsForSs,
  getCommentsForDrs,
  getCommentsForSs,
} from 'services/api/comments';
import { rejectDeviceReadyStory } from 'services/api/stories/device-ready-stories';
import { rejectStory } from 'services/api/stories/simple-stories';
import { DrsCommentsPayload, SsCommentsPayload } from 'types/comments';

interface StoryCommentsContextType {
  onGetComment: (postfix: string) => string | undefined;
  onSetComment: (postfix: string, comment: string) => void;
  isEditing: boolean;
  onSetIsEditing: (isEditing: boolean) => void;
  onCancel: () => void;
  onSaveData: () => Promise<void>;
}

const StoryCommentsContext = createContext<StoryCommentsContextType | null>(
  null,
);

interface StoryCommentsContextProviderProps<T extends boolean>
  extends PropsWithChildren {
  isSimpleStory: T;
}

const StoryCommentsContextProvider = <T extends boolean>({
  children,
  isSimpleStory,
}: StoryCommentsContextProviderProps<T>) => {
  const { id = '' } = useParams();

  const methods = useForm<Record<string, string>>({
    defaultValues: {},
  });
  const { reset, setValue, watch, getValues } = methods;

  const [isEditing, setIsEditing] = useState(false);

  const handleFetchComments = useCallback(
    async (id: string) => {
      if (isEditing) return;
      const fetchComments = isSimpleStory
        ? getCommentsForSs
        : getCommentsForDrs;
      const comments = await fetchComments(id);
      const mappedComments: Record<string, string> = {};
      comments.forEach((comment) => {
        mappedComments[comment.relatedItemIdentifier] = comment.comment;
      });
      reset(mappedComments);
    },
    [isEditing, isSimpleStory, reset],
  );

  useEffect(() => {
    id && handleFetchComments(id);
  }, [handleFetchComments, id]);

  const handleGetComment = useCallback(
    (postfix: string) => watch(postfix),
    [watch],
  );

  const handleSetComment = useCallback(
    (postfix: string, comment: string) => setValue(postfix, comment),
    [setValue],
  );

  const handleCancel = useCallback(() => {
    setIsEditing(false);
    reset({});
  }, [reset]);

  const handleSaveData = useCallback(async () => {
    const saveCb = isSimpleStory ? addCommentsForSs : addCommentsForDrs;
    const rejectStoryCb = isSimpleStory ? rejectStory : rejectDeviceReadyStory;
    type Payload = SsCommentsPayload | DrsCommentsPayload;

    const generalComment = getValues();
    const keys = Object.keys(generalComment);
    const stack: Payload[] = [];

    keys.forEach((key) => {
      const payload: Payload = {
        comment: generalComment[key],
        relatedItemIdentifier: key,
        type: key.split('_')[0],
        ...(isSimpleStory && { storyId: id }),
        ...(!isSimpleStory && { deviceReadyStoryId: id }),
      };
      stack.push(payload);
    });
    //@ts-ignore
    await saveCb(stack);
    await rejectStoryCb(id);
  }, [getValues, id, isSimpleStory]);

  return (
    <StoryCommentsContext.Provider
      value={{
        onGetComment: handleGetComment,
        onSetComment: handleSetComment,
        isEditing,
        onSetIsEditing: setIsEditing,
        onCancel: handleCancel,
        onSaveData: handleSaveData,
      }}
    >
      {children}
    </StoryCommentsContext.Provider>
  );
};

const useStoryCommentsContext = () => {
  const context = useContext(StoryCommentsContext);

  if (!context) {
    throw new Error(
      'useStoryCommentsContext must be used within a StoryCommentsContextProvider',
    );
  }

  return context;
};

export { StoryCommentsContextProvider, useStoryCommentsContext };

import { FC, ReactNode, useMemo } from 'react';

import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Box from '@mui/material/Box';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Button from 'view/components/Button';
import ConfirmationWarningIcon from 'view/icons/ConfirmationWarning';
import SuccessIcon from 'view/icons/SuccessIcon';
import WarningIcon from 'view/icons/WarningIcon';
import { black54 } from 'view/theme/colors';

import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogIcon,
} from './styled';

type ConfirmationDialogProps = {
  open: boolean;
  text: string;
  confirmText?: string;
  color?: 'primary' | 'error';
  icon?: ReactNode;
  children?: React.ReactNode;
  onConfirm: React.MouseEventHandler<HTMLButtonElement>;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  type?: 'danger' | 'success';
};

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  open = false,
  text,
  icon = null,
  color = 'error',
  confirmText,
  onConfirm,
  onClose,
  children = null,
  type,
}) => {
  const { t } = useTranslation();
  const renderIcon = useMemo(() => {
    switch (type) {
      case 'danger':
        return <WarningIcon />;
      case 'success':
        return <SuccessIcon />;
      default:
        return <ConfirmationWarningIcon color={color} />;
    }
  }, [color, type]);

  return (
    <StyledDialog
      open={open}
      aria-describedby="confirmation-dialog-description"
    >
      <StyledDialogIcon>{icon || renderIcon}</StyledDialogIcon>
      <Box position="absolute" top={8} right={8}>
        <IconButton
          color="inherit"
          onClick={onClose}
          aria-label="clear-search"
          disableRipple
          disableFocusRipple
          sx={{
            '&.MuiButtonBase-root:hover': {
              bgcolor: 'transparent',
              color: black54,
            },
          }}
        >
          <ClearOutlinedIcon />
        </IconButton>
      </Box>
      <StyledDialogContent>
        <DialogContentText id="confirmation-dialog-description">
          <Typography variant="body1" align="center" fontWeight={500}>
            {text}
          </Typography>
        </DialogContentText>
        {children}
      </StyledDialogContent>
      <StyledDialogActions disableSpacing>
        <Button
          type="submit"
          label={confirmText || t('modal.confirm')}
          color={color}
          onClick={onConfirm}
          fullWidth
        />
        <Button
          variant="text"
          label={t('modal.cancel')}
          onClick={onClose}
          fullWidth
        />
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default ConfirmationDialog;

import { FC } from 'react';

import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import ArrowLeftIcon from 'view/icons/ArrowLeft';
import { black8 } from 'view/theme/colors';

type BackButtonProps = {
  onClick?: () => void;
  sx?: SxProps;
};

const BackButton: FC<BackButtonProps> = ({ onClick, sx }) => {
  const navigate = useNavigate();

  const handleClick = () => navigate(-1);

  return (
    <Box sx={{ position: 'absolute', left: 0, top: 100, ...sx }}>
      <IconButton
        sx={{
          borderRadius: '0.2rem',
          backgroundColor: black8,
          width: '2.5rem',
          height: '2.5rem',
        }}
        color="primary"
        onClick={onClick || handleClick}
        className="back-button"
        aria-label="back"
        disableRipple
        disableFocusRipple
      >
        <ArrowLeftIcon />
      </IconButton>
    </Box>
  );
};

export default BackButton;

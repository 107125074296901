import { FC, MouseEvent, useMemo, useState } from 'react';

import Box from '@mui/material/Box';

import Chip from '../Chip';

interface TagsListProps {
  tags: string[];
  visibleTags?: number;
  isAllowedToExpand?: boolean;
}

const TagsList: FC<TagsListProps> = ({
  tags,
  visibleTags = 4,
  isAllowedToExpand = true,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClickSummTagsButton = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    isAllowedToExpand && setIsExpanded(true);
  };

  const tagsToRender = useMemo(() => {
    return isAllowedToExpand && isExpanded ? tags : tags.slice(0, visibleTags);
  }, [isAllowedToExpand, isExpanded, tags, visibleTags]);

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      gap="0.5rem"
      alignItems="center"
      justifyContent="flex-start"
      overflow={isExpanded ? 'scroll' : 'hidden'}
    >
      {tagsToRender.map((tag, index) => (
        <Chip
          label={tag}
          color="primary"
          key={index}
          sx={{
            maxWidth: tags.length > 3 ? '7.5rem' : '15rem',
            marginBottom: 0,
          }}
        />
      ))}
      {!isExpanded && tags.length > visibleTags && (
        <Box onClick={handleClickSummTagsButton}>
          <Chip
            label={`+${tags.length - visibleTags}`}
            color="primary"
            key={`${tags[0]}-tags-more`}
            sx={{
              maxWidth: '7.5rem',
              marginBottom: 0,
              cursor: isAllowedToExpand ? 'pointer' : 'default',
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default TagsList;

export type User = {
  id: string;
  userId: string;
  username: string;
  email: string;
  roles: ROLES_NAMES[];
  createdAt: Date;
  updatedAt: Date;
};

export enum ROLES_NAMES {
  storyCreator = 'STORY_CREATOR',
  storyComposer = 'STORY_COMPOSER',
  moderator = 'MODERATOR',
  robotHandler = 'ROBOT_HANDLER',
  admin = 'ADMIN',
}

export const ROLES_NAMES_TO_LABELS = {
  [ROLES_NAMES.storyCreator]: 'STORY_CREATOR',
  [ROLES_NAMES.storyComposer]: 'STORY_COMPOSER',
  [ROLES_NAMES.moderator]: 'MODERATOR',
  [ROLES_NAMES.robotHandler]: 'ROBOT_HANDLER',
  [ROLES_NAMES.admin]: 'ADMIN',
};

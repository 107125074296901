import { useCallback, useState } from 'react';

const useMultiCheckBox = (initialState?: string[]) => {
  const [checked, setChecked] = useState(() => new Set(initialState));

  const onChange = useCallback((idx: string) => {
    setChecked((prev) => {
      const newChecked = new Set(prev);
      if (newChecked.has(idx)) {
        newChecked.delete(idx);
      } else {
        newChecked.add(idx);
      }
      return newChecked;
    });
  }, []);

  const handleCheckAll = useCallback((idx: string[]) => {
    setChecked((prev) => {
      const newChecked = new Set(prev);
      idx.forEach((id) => newChecked.add(id));
      return newChecked;
    });
  }, []);

  const handleUncheckAll = useCallback(() => setChecked(new Set()), []);

  return { checked, onChange, handleCheckAll, handleUncheckAll };
};

export default useMultiCheckBox;

import React, { FC, MouseEvent, useCallback, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box/Box';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography';
import { EventBussEvent } from 'eventBuss';
import { useSubscribeEventBuss } from 'eventBuss/useSubscribeEventBuss';
import useFetchListDataWithFilteringAndSorting from 'hooks/useFetchListDataWithFilteringAndSorting';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import {
  getDevices,
  shareDeviceAccess,
  unshareDeviceAccess,
} from 'services/api/devices';
import { black, black54, purple, red, white } from 'view/theme/colors';

import { DeviceListTableItem } from '../../pages/devices/DevicesList/components/DevicesListTable/DeviceListTableItem';
import BackButton from '../BackButton';
import TablePagination from '../TablePagination';

const DevicesDrawer: FC = () => {
  const [open, setOpen] = useState(false);
  const [drsId, setDrsId] = useState('');

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { data, onChangePage, onRefetch } =
    useFetchListDataWithFilteringAndSorting(getDevices, {
      disabledQueryParams: true,
    });

  const handleCloseDrawer = () => setOpen(false);
  const handleOpenDrawer = () => setOpen(true);

  const handleToggleDrawer = useCallback((drsId: string) => {
    handleOpenDrawer();
    setDrsId(drsId);
  }, []);

  useSubscribeEventBuss(EventBussEvent.openDeviceSidebar, handleToggleDrawer);

  const isSsDrawerOpened = document.getElementById('simple-story-drawer');

  const screenWidth = window.innerWidth;
  const drawerWidth =
    screenWidth > 1538 ? screenWidth * 0.75 : screenWidth - 70;

  return (
    <Drawer
      open={open}
      id="device-drawer"
      onClose={handleCloseDrawer}
      sx={{
        '& .MuiDrawer-paper': {
          overflow: 'unset',
          width: '100%',
          maxWidth: drawerWidth,
        },
        '& .MuiBox-root': {
          overflow: 'unset',
        },
      }}
    >
      <Box
        position="relative"
        bgcolor={white}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-start"
        height="100%"
        overflow="scroll"
        onClick={(e) => e.stopPropagation()}
        px={8}
        py={5}
        width="100%"
        maxWidth={drawerWidth}
      >
        {isSsDrawerOpened && (
          <BackButton sx={{ top: 46 }} onClick={handleCloseDrawer} />
        )}
        <Box
          position="absolute"
          right={-20}
          top={46}
          p={1}
          onClick={handleCloseDrawer}
          sx={{ cursor: 'pointer', borderRadius: '4px' }}
          bgcolor={white}
          display="flex"
          alignItems="center"
        >
          <CloseIcon fontSize="medium" sx={{ color: black }} />
        </Box>
        <Box width="100%" overflow="auto">
          <Typography textAlign="left" fontSize={24} fontWeight={700} pt={1}>
            {t('devicesListPage.deviceManager')}
          </Typography>
          <Typography
            textAlign="left"
            fontSize={14}
            fontWeight={500}
            color={black54}
            pt={2}
          >
            {t('devicesListPage.modalSubtitle')}
          </Typography>
          <Stack flex={1} spacing={1} mt={4} width="100%">
            {data?.items.map(({ id, name, deviceReadyStories, updatedAt }) => {
              const isShared = deviceReadyStories.some((id) => id === drsId);

              return (
                <DeviceListTableItem
                  key={id}
                  updatedDate={updatedAt}
                  name={name}
                  id={id}
                  isCopyAvailable={false}
                  controls={
                    <Typography
                      color={isShared ? red : purple}
                      textAlign="end"
                      flex={0.2}
                      sx={{ cursor: 'pointer' }}
                      onClick={async (e: MouseEvent<HTMLElement>) => {
                        e.stopPropagation();
                        try {
                          const cb = isShared
                            ? unshareDeviceAccess
                            : shareDeviceAccess;
                          await cb(id, drsId);

                          const notificationText = isShared
                            ? t('notifications.deviceDeniedSuccessfully')
                            : t('notifications.deviceSharedSuccessfully');

                          onRefetch();

                          enqueueSnackbar(notificationText, {
                            variant: 'success',
                          });
                        } catch (e) {
                          enqueueSnackbar(
                            t('notifications.somethingWentWrong'),
                            {
                              variant: 'error',
                            },
                          );
                        }
                      }}
                    >
                      {isShared
                        ? t('devicesListPage.revokeAccess')
                        : t('devicesListPage.shareAccess')}
                    </Typography>
                  }
                />
              );
            })}
            <TablePagination {...data?.meta} onPageChange={onChangePage} />
          </Stack>
        </Box>
      </Box>
    </Drawer>
  );
};

export default DevicesDrawer;

import { useMemo } from 'react';

import { useIsCreator } from 'hooks/useIsCreator';
import useRoleService from 'hooks/useRoleService';

import { IRoleProps } from './index';

const useRole = ({
  allowedRoles = [],
  creatorId,
}: Omit<IRoleProps, 'children'>) => {
  const isCreator = useIsCreator(creatorId);
  const { checkRole, isAdmin } = useRoleService();

  return useMemo(() => {
    if (!isAdmin) {
      const isRoleAllowed = checkRole(allowedRoles);
      if (creatorId) {
        return isRoleAllowed && isCreator;
      } else {
        return isRoleAllowed;
      }
    } else {
      return true;
    }
  }, [allowedRoles, checkRole, creatorId, isAdmin, isCreator]);
};

export default useRole;

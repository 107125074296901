import { ChangeEvent, FC, useCallback, useState } from 'react';

import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import useDebounce from 'hooks/useDebounce';
import useDidUpdate from 'hooks/useDidUpdate';
import { useTranslation } from 'react-i18next';

import Search from '../Search';

interface FilterSortBlockSearchProps {
  setSearchQuery: (value: string) => void;
  sx?: SxProps;
}

export const FilterSortBlockSearch: FC<FilterSortBlockSearchProps> = ({
  setSearchQuery,
  sx,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');

  const handleClearSearch = useCallback(() => setValue(''), [setValue]);
  const handleUpdateSearchValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setValue(event.target.value),
    [setValue],
  );

  const debouncedSearchValue = useDebounce(value);

  useDidUpdate(() => {
    setSearchQuery(debouncedSearchValue);
  }, [debouncedSearchValue]);

  return (
    <Box sx={{ marginLeft: 'auto', ...sx }}>
      <Search
        small
        name="search"
        value={value}
        placeholder={t('filtering.search') as string}
        onChange={handleUpdateSearchValue}
        onClear={handleClearSearch}
      />
    </Box>
  );
};

import appConfig from 'config/appConfig';

class pathNameManager {
  private readonly IMAGE = 'images';
  private readonly AUDIO = 'audio';
  private readonly RECORD = 'record';
  private readonly RESIZED = 'resized';
  private readonly PATH_TO_STORE = `${appConfig.APP_URL}/${appConfig.CONTENT_PATH}`;

  public checkIsPathTemp = (path: string = '') =>
    path.includes(appConfig.TEMP_PATH + '/');

  public dropPathTemp = (path: string) =>
    path.split(`${appConfig.TEMP_PATH}/` || '')[1];

  public pathToOriginalImageStoreFromResize = (path: string) => {
    return path.replace(`-${this.RESIZED}`, '');
  };

  public getFileNameFromPath = (path: string) => {
    const pathParts = path.split('/');
    return pathParts[pathParts.length - 1];
  };

  public createImagePathForUpload = (
    id: string,
    name: string,
    isResized: boolean = false,
  ) => {
    const nameWithoutExtension = name.split('.')?.[0];
    const extension = name.split('.')[1].toLowerCase();
    const resizedName = `${nameWithoutExtension}-${this.RESIZED}.${extension}`;
    return (
      isResized
        ? `${id}/${this.IMAGE}/${resizedName}`
        : `${id}/${this.IMAGE}/${name}`
    ).replace(' ', '');
  };

  public dropPathToImageStore = (path: string) =>
    path.split(`${this.PATH_TO_STORE}/` || '')[1];

  public createAudioPathForUpload = (id: string, name: string) =>
    `${id}/${this.AUDIO}/${name}`.replace(' ', '');

  public createPathForRendering = (path: string) =>
    `${this.PATH_TO_STORE}/${path}`;

  public createRecordPathForUpload = (id: string, name: string) =>
    `${id}/${this.RECORD}/${name}`.replace(' ', '');
}

export default pathNameManager;

import { purple } from 'view/theme/colors';

export default function CheckIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M13.3332 3.99902L5.99984 11.3324L2.6665 7.99902"
        stroke={purple}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import { useEffect } from 'react';

import eventBuss, { EventBussEvent } from './index';

export const useSubscribeEventBuss = (
  event: EventBussEvent,
  cb: (...data: any[]) => void,
) => {
  useEffect(() => {
    eventBuss.subscribe(event, cb);
    return () => {
      eventBuss.unsubscribe(event, cb);
    };
  }, [cb, event]);
};

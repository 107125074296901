import { ROLES_NAMES } from 'types/users';

class RoleService {
  userRoles: ROLES_NAMES[];
  constructor(userRoles: ROLES_NAMES[]) {
    this.userRoles = userRoles;
  }

  get isStoryCreator() {
    return this.userRoles.includes(ROLES_NAMES.storyCreator);
  }

  get isStoryComposer() {
    return this.userRoles.includes(ROLES_NAMES.storyComposer);
  }

  get isModerator() {
    return this.userRoles.includes(ROLES_NAMES.moderator);
  }

  get isRobotHandler() {
    return this.userRoles.includes(ROLES_NAMES.robotHandler);
  }

  get isAdmin() {
    return this.userRoles.includes(ROLES_NAMES.admin);
  }

  checkRole = (allowedRoles: ROLES_NAMES[]) =>
    allowedRoles.some((role) => this.userRoles.includes(role));
}

export default RoleService;

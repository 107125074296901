import React, { FC, MouseEvent } from 'react';

import { Stack } from '@mui/material';
import eventBuss, { EventBussEvent } from 'eventBuss';
import handleCloneDrs from 'helpers/handleCloneDrs';
import handleSetInitialStateForDRS from 'helpers/handleSetInitialStateForDRS';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { STORIES_PATH_EDIT } from 'routes/routes';
import { deleteDeviceReadyStory } from 'services/api/stories/device-ready-stories';
import { ROLES_NAMES } from 'types/users';

import CardRowControls from '../CardRowControls';
import { ShareDeviceFromCard } from '../ShareDeviceFromCard';

interface DeviceReadyStoryCardRowControlsProps {
  storyId: string;
  originalBasicStoryId: string;
  creatorId: string;
  onDelete?: (id?: string) => void;
  onCopy?: (id?: string) => void;
  isEditShown?: boolean;
  isDeleteShown?: boolean;
  isShareDeviceShown?: boolean;
  onClosePopOver?: () => void;
}

const DeviceReadyStoryCardRowControls: FC<
  DeviceReadyStoryCardRowControlsProps
> = ({
  storyId,
  onDelete,
  isDeleteShown,
  isEditShown,
  creatorId,
  originalBasicStoryId,
  onCopy,
  isShareDeviceShown,
  onClosePopOver,
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleClickEdit = () => {
    onClosePopOver?.();
    navigate(`${STORIES_PATH_EDIT}/${originalBasicStoryId}/robot/${storyId}`);
    handleSetInitialStateForDRS({ isEditing: true });
  };

  const handleDelete = async (e?: MouseEvent<HTMLElement>) => {
    e?.stopPropagation();
    onClosePopOver?.();
    await deleteDeviceReadyStory(storyId);
    onDelete?.(storyId);
    eventBuss.dispatch(EventBussEvent.importDrs);
  };

  const handleCopy = async (e?: MouseEvent<HTMLElement>) => {
    e?.stopPropagation();
    onClosePopOver?.();
    enqueueSnackbar(t('notifications.cloningStarted') || '', {
      variant: 'success',
    });
    await handleCloneDrs(storyId);
    enqueueSnackbar(t('notifications.clonedSuccessfully') || '', {
      variant: 'success',
    });
    onCopy?.(storyId);
    eventBuss.dispatch(EventBussEvent.cloneDrs);
  };

  return (
    <Stack direction="column" spacing={1} alignItems="flex-start">
      {isShareDeviceShown && (
        <ShareDeviceFromCard
          drsId={storyId}
          sx={{ width: '100%' }}
          onClick={onClosePopOver}
        />
      )}
      <CardRowControls
        withText
        creatorId={creatorId}
        isDeleteShown={isDeleteShown}
        isEditShown={isEditShown}
        onEdit={handleClickEdit}
        onDelete={handleDelete}
        onCopy={handleCopy}
        deleteStoreRoles={[ROLES_NAMES.storyComposer]}
        editStoreRoles={[ROLES_NAMES.storyComposer]}
        copyStoreRoles={[ROLES_NAMES.storyComposer]}
        containerProps={{
          direction: 'column',
          spacing: 1,
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          width: '100%',
        }}
      />
    </Stack>
  );
};

export default DeviceReadyStoryCardRowControls;

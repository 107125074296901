import { Route } from 'react-router-dom';

import {
  RouteInterface,
  RouteWithRoutesInterface,
} from '../routes/routes.config';

const generateRoute = (
  routes: (RouteInterface | RouteWithRoutesInterface)[],
): React.ReactNode[] => {
  return routes.map((routeItem, index) => {
    if ('routes' in routeItem) {
      return (
        <Route element={routeItem.element} key={index}>
          {generateRoute(routeItem.routes)}
        </Route>
      );
    } else {
      return (
        <Route
          key={routeItem.path}
          path={routeItem.path}
          element={routeItem.element}
        />
      );
    }
  });
};

export default generateRoute;

import { FC, MouseEvent, useCallback } from 'react';

import { StackProps } from '@mui/material';
import eventBuss, { EventBussEvent } from 'eventBuss';
import { useNavigate } from 'react-router-dom';
import { STORIES_PATH_EDIT } from 'routes/routes';
import { deleteStory } from 'services/api/stories/simple-stories';
import { ROLES_NAMES } from 'types/users';

import CardRowControls from '../CardRowControls';

interface SimpleStoryCardRowControlsProps {
  storyId: string;
  creatorId: string;
  onDelete?: (id?: string) => void;
  isEditShown?: boolean;
  isDeleteShown?: boolean;
  withText?: boolean;
  containerProps?: StackProps;
}
const SimpleStoryCardRowControls: FC<SimpleStoryCardRowControlsProps> = ({
  storyId,
  onDelete,
  isDeleteShown = true,
  isEditShown = true,
  creatorId,
  withText = false,
  containerProps,
}) => {
  const navigate = useNavigate();

  const handleEdit = useCallback(
    (e?: MouseEvent<HTMLElement>) => {
      e?.stopPropagation();
      navigate(`${STORIES_PATH_EDIT}/${storyId}`);
    },
    [navigate, storyId],
  );

  const handleDelete = useCallback(
    async (e?: MouseEvent<HTMLElement>) => {
      e?.stopPropagation();
      await deleteStory(storyId);
      onDelete?.(storyId);
      eventBuss.dispatch(EventBussEvent.removeSs);
    },
    [onDelete, storyId],
  );

  return (
    <CardRowControls
      withText={withText}
      isCopyShown={false}
      isEditShown={isEditShown}
      isDeleteShown={isDeleteShown}
      creatorId={creatorId}
      onEdit={handleEdit}
      onDelete={handleDelete}
      editStoreRoles={[ROLES_NAMES.storyCreator]}
      deleteStoreRoles={[ROLES_NAMES.storyCreator]}
      containerProps={containerProps}
    />
  );
};

export default SimpleStoryCardRowControls;

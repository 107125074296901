import { useEffect } from 'react';

import { useCacheImagesContext } from 'contexts/cache-images';

const useCheckIfImageValid = (url: string) => {
  const { handleGetCache, handleSetCache, isHasCache } =
    useCacheImagesContext();

  const cacheValue = isHasCache(url);

  useEffect(() => {
    if (!cacheValue) {
      const img = new Image();
      img.src = url;
      img.onload = () => handleSetCache(url, true);
      img.onerror = () => handleSetCache(url, false);
    }
  }, [cacheValue, handleGetCache, handleSetCache, url]);

  return handleGetCache(url);
};

export default useCheckIfImageValid;

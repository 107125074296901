import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

const CacheImagesContext = createContext<{
  handleSetCache: (key: string, value: boolean) => void;
  handleGetCache: (key: string) => boolean | undefined;
  isHasCache: (key: string) => boolean;
}>({
  handleSetCache: () => {},
  handleGetCache: () => undefined,
  isHasCache: () => false,
});

const CacheImagesProvider: FC<PropsWithChildren> = ({ children }) => {
  const [cache, setCache] = useState<Map<string, boolean>>(new Map());

  const handleSetCache = useCallback((key: string, value: boolean) => {
    setCache((prev) => {
      const newCache = new Map(prev);
      newCache.set(key, value);
      return newCache;
    });
  }, []);

  const handleGetCache = useCallback((key: string) => cache.get(key), [cache]);

  const isHasCache = useCallback((url: string) => cache.has(url), [cache]);

  const cacheValue = useMemo(
    () => ({ handleSetCache, handleGetCache, isHasCache }),
    [handleGetCache, handleSetCache, isHasCache],
  );

  return (
    <CacheImagesContext.Provider value={cacheValue}>
      {children}
    </CacheImagesContext.Provider>
  );
};

const useCacheImagesContext = () => useContext(CacheImagesContext);

export { CacheImagesProvider, useCacheImagesContext };

export type PaginatedResponse<T> = {
  items: T[];
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
};

export interface ApiGetListParams {
  limit?: number;
  offset?: number;
  orderBy?: string;
  orderDirection?: Order;
  searchQuery?: string;
  tags?: string[];
  status?: string;
  statuses?: string[];
  languages?: string[];
  [key: string]: any;
}

export interface LibraryStats {
  published: number;
  submitted: number;
  rejected: number;
  draft: number;
  bookmarks: number;
}

export enum Order {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type SortProperty = {
  value: string;
  label: string;
};

export const DEFAULT_PAGE_SIZE = 12;

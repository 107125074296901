import { ReactElement } from 'react';

import { StyledButton, StyledLoadingButton } from './styled';

type ButtonProps = {
  label: string;
  disabled?: boolean;
  withLoader?: boolean;
  loading?: boolean;
  type?: 'button' | 'submit' | 'reset';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLButtonElement>;
  variant?: 'text' | 'outlined' | 'contained' | 'stroke';
  fullWidth?: boolean;
  className?: string;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  sx?: object;
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
};

const Button = ({
  variant = 'contained',
  label,
  color,
  disabled = false,
  type,
  onClick,
  onMouseEnter,
  onMouseLeave,
  fullWidth,
  className,
  startIcon,
  endIcon,
  withLoader = false,
  loading = false,
  sx,
}: ButtonProps) => {
  if (withLoader) {
    return (
      <StyledLoadingButton
        sx={sx}
        type={type}
        disabled={disabled}
        loading={loading}
        variant={variant}
        color={color}
        onClick={onClick}
        fullWidth={fullWidth}
        className={className}
        startIcon={startIcon}
        endIcon={endIcon}
      >
        {label}
      </StyledLoadingButton>
    );
  }
  return (
    <StyledButton
      sx={sx}
      type={type}
      disabled={disabled}
      variant={variant}
      color={color}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      fullWidth={fullWidth}
      className={className}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {label}
    </StyledButton>
  );
};

export default Button;

export default function SuccessIcon() {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="u:check-circle">
        <path
          id="Vector"
          d="M31.893 19.0447L22.598 28.3613L19.023 24.7863C18.8288 24.5595 18.5898 24.3753 18.321 24.2453C18.0521 24.1152 17.7594 24.0421 17.461 24.0306C17.1626 24.0191 16.865 24.0694 16.587 24.1783C16.3089 24.2872 16.0564 24.4524 15.8453 24.6636C15.6341 24.8748 15.4689 25.1273 15.36 25.4053C15.251 25.6833 15.2008 25.9809 15.2123 26.2793C15.2238 26.5777 15.2969 26.8705 15.4269 27.1393C15.557 27.4081 15.7412 27.6471 15.968 27.8413L21.0597 32.9547C21.2621 33.1555 21.5022 33.3144 21.7662 33.4222C22.0302 33.53 22.3129 33.5847 22.598 33.583C23.1664 33.5806 23.7111 33.355 24.1147 32.9547L34.948 22.1213C35.1511 21.9199 35.3123 21.6803 35.4223 21.4163C35.5323 21.1522 35.5889 20.869 35.5889 20.583C35.5889 20.297 35.5323 20.0138 35.4223 19.7498C35.3123 19.4857 35.1511 19.2461 34.948 19.0447C34.5421 18.6411 33.9929 18.4146 33.4205 18.4146C32.8481 18.4146 32.299 18.6411 31.893 19.0447ZM25.9997 4.33301C21.7144 4.33301 17.5254 5.60374 13.9623 7.9845C10.3993 10.3653 7.62219 13.7491 5.98229 17.7082C4.3424 21.6673 3.91332 26.0237 4.74934 30.2266C5.58535 34.4296 7.6489 38.2902 10.679 41.3203C13.7092 44.3505 17.5698 46.414 21.7727 47.25C25.9756 48.086 30.3321 47.657 34.2912 46.0171C38.2502 44.3772 41.6341 41.6001 44.0149 38.037C46.3956 34.474 47.6663 30.2849 47.6663 25.9997C47.6663 23.1544 47.1059 20.3369 46.0171 17.7082C44.9282 15.0795 43.3323 12.691 41.3203 10.679C39.3084 8.66709 36.9199 7.07114 34.2912 5.98228C31.6624 4.89343 28.845 4.33301 25.9997 4.33301ZM25.9997 43.333C22.5715 43.333 19.2203 42.3164 16.3698 40.4118C13.5193 38.5072 11.2977 35.8001 9.98577 32.6329C8.67385 29.4656 8.3306 25.9804 8.99941 22.6181C9.66822 19.2558 11.3191 16.1673 13.7432 13.7432C16.1673 11.319 19.2558 9.66821 22.6181 8.9994C25.9805 8.33059 29.4656 8.67384 32.6329 9.98576C35.8001 11.2977 38.5072 13.5193 40.4118 16.3698C42.3164 19.2202 43.333 22.5715 43.333 25.9997C43.333 30.5968 41.5068 35.0056 38.2562 38.2562C35.0056 41.5068 30.5968 43.333 25.9997 43.333Z"
          fill="#51A873"
        />
      </g>
    </svg>
  );
}

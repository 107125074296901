import { FC, useMemo } from 'react';

import { BoxProps, TypographyProps } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { StoryStatus } from 'types/stories';
import { green, purple, yellow } from 'view/theme/colors';

interface StoryStatusProps {
  status: StoryStatus;
}

const getTheme = (
  status: StoryStatus,
): { container: BoxProps; text: TypographyProps } => {
  switch (status) {
    case StoryStatus.rejected: {
      return {
        container: {
          bgcolor: 'rgba(231, 158, 72, 0.08)',
        },
        text: {
          color: yellow,
        },
      };
    }
    case StoryStatus.draft: {
      return {
        container: {
          bgcolor: 'rgba(72, 83, 231, 0.08)',
        },
        text: {
          color: purple,
        },
      };
    }
    default: {
      return {
        container: {
          bgcolor: 'rgba(81, 168, 115, 0.08)',
        },
        text: {
          color: green,
        },
      };
    }
  }
};

const StoryStatusComponent: FC<StoryStatusProps> = ({ status }) => {
  const { t } = useTranslation();
  const theme = getTheme(status);

  const statusText = useMemo(
    () => ({
      [StoryStatus.draft]: t('storyStatus.draft'),
      [StoryStatus.published]: t('storyStatus.published'),
      [StoryStatus.rejected]: t('storyStatus.rejected'),
      [StoryStatus.submitted]: t('storyStatus.submitted'),
    }),
    [t],
  );

  return (
    <Box position="relative" px={1.5} borderRadius={0.5}>
      <Box
        {...theme.container}
        borderRadius={0.5}
        position="absolute"
        left={0}
        right={0}
        top={-2}
        bottom={-2}
      />
      <Typography textTransform="capitalize" {...theme.text}>
        {statusText[status]?.toLowerCase()}
      </Typography>
    </Box>
  );
};

export default StoryStatusComponent;

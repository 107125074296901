import { useCallback, useEffect, useState } from 'react';

import deepCopy from 'helpers/deepCopy';
import { getTags } from 'services/api/tags';
import { FilterOption, FiltersData } from 'types/filters';

const useTags = () => {
  const [tags, setTags] = useState<FiltersData>({});

  useEffect(() => {
    getTags().then((data) => {
      setTags((prev) => {
        prev = data.reduce<Record<string, FilterOption>>((acc, item) => {
          acc[item.title] = {
            label: item.title,
            value: item.title,
            checked: false,
          } as FilterOption;
          return acc;
        }, {});
        return deepCopy(prev);
      });
    });
  }, [setTags]);

  const handleClearSelectedTags = useCallback(() => {
    setTags((prev) => {
      Object.keys(prev).forEach((key) => {
        prev[key].checked = false;
      });
      return deepCopy(prev);
    });
  }, []);

  const handleGetSelectedTags = useCallback(() => {
    return Object.keys(tags).filter((key) => tags[key].checked);
  }, [tags]);

  return {
    tags,
    setTags,
    onClearSelectedTags: handleClearSelectedTags,
    onGetSelectedTags: handleGetSelectedTags,
  };
};

export default useTags;

import { DeviceReadyStoryPageState } from 'contexts/device-ready-story/device-ready-story.type';

export const INITIAL_DRS_PAGE_STATE = 'INITIAL_DRS_PAGE_STATE';

const handleSetInitialStateForDRS = (
  data: Partial<DeviceReadyStoryPageState>,
) => {
  sessionStorage.setItem(INITIAL_DRS_PAGE_STATE, JSON.stringify(data));
};

export default handleSetInitialStateForDRS;

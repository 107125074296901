import React, { FC, ReactElement } from 'react';

import { Popover, PopoverProps } from '@mui/material';

export interface PopOverProps
  extends Omit<PopoverProps, 'children' | 'content' | 'open'> {
  children: (params: {
    onOpen: (event: React.MouseEvent<HTMLElement>) => void;
    onClose: () => void;
    isOpen: boolean;
  }) => ReactElement;
  content: (params: { onClose: () => void }) => ReactElement;
  onClosing?: () => void;
}
const PopOver: FC<PopOverProps> = ({
  children,
  id,
  content,
  onClosing,
  ...restProps
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClosing?.();
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' + id : undefined;

  return (
    <>
      {children({ onOpen: handleClick, onClose: handleClose, isOpen: open })}
      {open && (
        <Popover
          id={popoverId}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          {...restProps}
        >
          {content({ onClose: handleClose })}
        </Popover>
      )}
    </>
  );
};

export default PopOver;

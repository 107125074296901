import { Dispatch, FC, SetStateAction, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { Order } from 'services/api/types';

import SortDropdown from '../SortDropdown';

interface FilterSortBlockSortProps {
  sorting: [string, Order];
  setSorting: Dispatch<SetStateAction<[string, Order]>>;
  sortItems?: { value: string; label: string }[];
}

export const FilterSortBlockSort: FC<FilterSortBlockSortProps> = ({
  sorting,
  setSorting,
}) => {
  const { t } = useTranslation();

  const sortItems = useMemo(
    () => [
      { value: 'created_at', label: t('filtering.date') },
      { value: 'title', label: t('filtering.name') },
    ],
    [t],
  );

  return (
    <SortDropdown items={sortItems} sorting={sorting} setSorting={setSorting} />
  );
};

import { DependencyList, useEffect, useRef } from 'react';

import useDebouncedCallback from '../useDebouncedCallback';
const useDidUpdate = (
  effect: () => void,
  dependencies: DependencyList = [],
) => {
  const isMounted = useRef(false);
  const handleMount = useDebouncedCallback(() => {
    isMounted.current = true;
  }, 50);
  useEffect(() => {
    if (isMounted.current) {
      return effect();
    }
    handleMount();
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useDidUpdate;

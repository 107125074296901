import { format } from 'date-fns';
import { enUS, es, ja } from 'date-fns/locale';

export type LocaleKeyType = 'en-US' | 'jpn' | 'es';

const locales: Record<LocaleKeyType, Locale> = {
  'en-US': enUS,
  jpn: ja,
  es: es,
};

export const formatDateWithTime = (
  date: Date,
  formatDate = 'MMM dd, yyyy',
  locale: LocaleKeyType = 'en-US',
) =>
  `${format(new Date(date), formatDate, {
    locale: locales[locale],
  })} at ${format(new Date(date), 'p', {
    locale: locales[locale],
  })}`;

export const formatDate = (
  date: Date,
  formatDate = 'MMM dd, yyyy',
  locale: LocaleKeyType = 'en-US',
) => `${format(new Date(date), formatDate, { locale: locales[locale] })}`;

export const calculateHoursDifference = (date1: Date, date2: Date) => {
  const timeDiff = Math.abs(date1.getTime() - date2.getTime());

  return timeDiff / (1000 * 60 * 60);
};

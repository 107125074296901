class AppConfig {
  AWS_REGION = process.env.REACT_APP_AWS_REGION;
  COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID;
  COGNITO_APP_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID;
  API_URL = process.env.REACT_APP_API_URL;
  AWS_BUCKET_NAME = process.env.REACT_APP_AWS_BUCKET_NAME;
  AWS_IDENTITY_POOL_ID = process.env.REACT_APP_AWS_IDENTITY_POOL_ID;
  CONTENT_PATH = 'content';
  TEMP_PATH = 'temp';
  APP_URL = process.env.REACT_APP_APP_URL;
}

const appConfig = new AppConfig();

export default appConfig;

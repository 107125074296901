import React, { useCallback, useEffect, useMemo, useState } from 'react';

import useFetch from 'hooks/useFetch';
import {
  ApiGetListParams,
  DEFAULT_PAGE_SIZE,
  Order,
  PaginatedResponse,
} from 'services/api/types';

import useDidUpdate from '../useDidUpdate';
import useUpdateQueryData from '../useUpdateQueryData';

interface Params {
  disabledQueryParams?: boolean;
}

const useFetchListDataWithFilteringAndSorting = <T>(
  cb: (params: ApiGetListParams) => Promise<PaginatedResponse<T>> | undefined,
  { disabledQueryParams }: Params = {},
) => {
  const { page: pageQuery, handleUpdatePage: handleUpdatePageQuery } =
    useUpdateQueryData();
  const [pageState, setPageSate] = useState(1);
  const [tags, setTags] = useState<string[]>([]);
  const [languages, setLanguages] = useState<string[]>([]);
  const [sorting, setSorting] = useState<[string, Order]>(['', Order.DESC]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [status, setStatus] = useState('');
  const [statuses, setStatuses] = useState<string[]>([]);

  const page = disabledQueryParams ? pageState : pageQuery;
  const handleUpdatePage = disabledQueryParams
    ? setPageSate
    : handleUpdatePageQuery;

  const memoisedSorting = useMemo(() => sorting, [sorting]);
  const memoisedTags = useMemo(() => tags, [tags]);
  const memoisedDeps = useMemo(
    () => [page, searchQuery, memoisedSorting, memoisedTags, status],
    [page, searchQuery, memoisedSorting, memoisedTags, status],
  );
  const memoisedLanguages = useMemo(() => languages, [languages]);
  const memoisedStatuses = useMemo(() => statuses, [statuses]);

  const isFiltersApplied = useMemo(() => {
    return (
      memoisedLanguages.length > 0 ||
      memoisedTags.length > 0 ||
      memoisedStatuses.length > 0 ||
      !!searchQuery
    );
  }, [
    memoisedLanguages.length,
    memoisedStatuses.length,
    memoisedTags.length,
    searchQuery,
  ]);

  const INITIAL_DATA = useMemo(
    () =>
      ({
        items: [] as Array<T>,
        meta: {
          totalItems: 0,
          currentPage: 1,
          totalPages: 1,
          itemsPerPage: DEFAULT_PAGE_SIZE,
          itemCount: 0,
        },
      } as PaginatedResponse<T>),
    [],
  );

  const cbParams = useMemo(
    () => ({
      offset: (page - 1) * DEFAULT_PAGE_SIZE,
      searchQuery,
      orderBy: memoisedSorting[0],
      orderDirection: memoisedSorting[1],
      tags: memoisedTags,
      languages: memoisedLanguages,
      ...(status && { status }),
      statuses: memoisedStatuses,
    }),
    [
      memoisedLanguages,
      memoisedSorting,
      memoisedStatuses,
      memoisedTags,
      page,
      searchQuery,
      status,
    ],
  );

  const handleFetchStories = useCallback(async () => {
    return cb(cbParams);
  }, [cb, cbParams]);

  const { data, onRefetch, fetchLoading, isFetched } = useFetch<
    PaginatedResponse<T> | undefined
  >(handleFetchStories, INITIAL_DATA, memoisedDeps);

  useDidUpdate(() => {
    handleUpdatePage(1);
  }, [searchQuery, tags]);

  useEffect(() => {
    handleUpdatePage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      handleUpdatePage(newPage);
    },
    [handleUpdatePage],
  );

  const isFilterShown = useMemo(
    () => (!isFiltersApplied && !!data?.items.length) || isFiltersApplied,
    [data?.items.length, isFiltersApplied],
  );

  return {
    data,
    onChangePage: handleChangePage,
    sorting: memoisedSorting,
    onSetSorting: setSorting,
    searchQuery,
    onSetSearchQuery: setSearchQuery,
    tags,
    onSetTags: setTags,
    onSetStatus: setStatus,
    onRefetch,
    fetchLoading,
    isFetched,
    onSetLanguages: setLanguages,
    languages,
    statuses,
    onSetStatuses: setStatuses,
    isFiltersApplied,
    isFilterShown,
  };
};

export default useFetchListDataWithFilteringAndSorting;

import MuiLoadingButton from '@mui/lab/LoadingButton';
import MuiButton from '@mui/material/Button';
import { styled } from '@mui/system';
import {
  black32,
  black40,
  black54,
  black8,
  darkGreen,
  gray,
  lightPurple,
  purple,
  red,
  white,
  yellow,
} from 'view/theme/colors';

const buttonStyles = `
&.MuiButton-root {
  text-transform: none;
  border-radius: 0.5rem;
  box-shadow: none;
  padding: 0.75rem 1.5rem;
  font-weight: 700;
  font-size: 1rem;
  line-height: 24px;
  white-space: nowrap;
}
&.clear-notifications {
  font-size: 0.85rem;
  font-weight: 700;
  line-height: 24px;
  padding: 0rem 0.75rem;
  margin-bottom:0.5rem;
  color:${black54};
}

&.MuiButton-textError {
  svg {
    path {
      stroke: ${red};
    }
  }
}

&.MuiButton-contained {
  background: ${purple};

  &.MuiButton-containedSuccess {
    color: ${white};
    background: ${darkGreen};
  }
  
  &.MuiButton-containedWarning {
    color: ${white};
    background: ${yellow};
  }

  &.MuiButton-containedError {
    background: ${red};
  }

  &.Mui-disabled {
    background: ${black8};
    color: ${black32};

    svg path {
      stroke: ${black32};
    }
  }
}

.MuiButton-outlined {
  color: ${black40};
  border: 1px solid ${gray};
}

&.MuiLoadingButton-loading {
  color: transparent !important;
}

&.MuiButton-containedSecondary {
    color: ${purple};
    background: ${lightPurple};
    
    &:hover {
      color: ${white};
      background: ${purple};
    }
  }
`;

export const StyledButton = styled<any>(MuiButton)`
  ${buttonStyles}
`;

export const StyledLoadingButton = styled<any>(MuiLoadingButton)`
  ${buttonStyles}
`;

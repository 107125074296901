import {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { HubCapsule } from '@aws-amplify/core';
import { Auth, Hub } from 'aws-amplify';
import { parseError } from 'helpers/error-parser';
import { useSnackbar } from 'notistack';
import { getProfile } from 'services/api/user';
import { UserService } from 'services/users/user';
import { User } from 'types/users';
import { SignInFormData } from 'view/pages/auth/forms';
import { VIEW_MODE_STORE_KEY } from 'view/pages/library/LibraryList';

export interface AuthData extends Omit<User, 'createdAt' | 'updatedAt'> {
  isAuthenticated: boolean;
  isLoading: boolean;
  username: string;
  createdAt?: Date;
  updatedAt?: Date;
  isCompletedNewPasswordSetup?: boolean;
}

const initialData: AuthData = {
  createdAt: undefined,
  id: '',
  roles: [],
  updatedAt: undefined,
  userId: '',
  isAuthenticated: false,
  isLoading: true,
  username: '',
  email: '',
  isCompletedNewPasswordSetup: false,
};

export const AuthContext = createContext<
  AuthData & { onLogout: () => void; onSignIn: (data: SignInFormData) => void }
>({
  ...initialData,
  onLogout: () => {},
  onSignIn: () => {},
});

const AuthListener = async (
  data: HubCapsule,
  setAuthData: React.Dispatch<React.SetStateAction<AuthData>>,
) => {
  switch (data.payload.event) {
    case 'customPasswordSetupCompleted':
      setAuthData((prevState) => ({
        ...prevState,
        isCompletedNewPasswordSetup: true,
      }));
      break;
    case 'signIn':
      try {
        const dbUser = await UserService.createUserIfNotExists();
        setAuthData((prev) => ({
          isAuthenticated: !prev.isCompletedNewPasswordSetup,
          isLoading: false,
          ...dbUser,
        }));
      } catch (err) {
        console.error(err);
      }
      break;
    case 'signOut':
      localStorage.clear();
      setAuthData({ ...initialData, isLoading: false });
      break;
    case 'signIn_failure':
      localStorage.clear();
      break;
  }
};

type Props = {
  children: ReactElement;
};

export const AuthProvider = ({ children }: Props) => {
  const [authData, setAuthData] = useState<AuthData>({ ...initialData });
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    const listener = Hub.listen('auth', async (data) =>
      AuthListener(data, setAuthData),
    );

    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then(async () => {
        const profile = await getProfile();

        return setAuthData((prev) => ({
          isAuthenticated: !prev.isCompletedNewPasswordSetup,
          isLoading: false,
          ...profile,
        }));
      })
      .catch(() => {
        return setAuthData({ ...initialData, isLoading: false });
      });

    return () => {
      Hub.remove('auth', listener);
    };
  }, []);

  const handleLogout = useCallback(async () => {
    try {
      await Auth.signOut({ global: true });
      sessionStorage.removeItem(VIEW_MODE_STORE_KEY);
    } catch (err) {
      if (parseError(err) === 'Access Token has been revoked') {
        await Auth.signOut();
      }
    }
  }, []);

  const handleSignIn = useCallback(
    async (data: SignInFormData) => {
      const { username, password } = data;
      try {
        setAuthData((prevState) => ({
          ...prevState,
          isCompletedNewPasswordSetup: false,
        }));
        await Auth.signIn({
          username,
          password,
        });
      } catch (e: any) {
        enqueueSnackbar(e.message, {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar],
  );

  const authContextValue = useMemo(
    () => ({
      ...authData,
      onLogout: handleLogout,
      onSignIn: handleSignIn,
    }),
    [authData, handleLogout, handleSignIn],
  );

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }

  return context;
};

import { User } from '../../types/users';
import { createProfile, getProfile } from '../api/user';

export class UserService {
  static async createUserIfNotExists(): Promise<User> {
    const user = await UserService.getCurrentUser();
    if (user) {
      return user;
    }
    return UserService.createNewUser();
  }

  private static async getCurrentUser(): Promise<any | User> {
    try {
      return await getProfile();
    } catch (e) {
      return null;
    }
  }

  private static async createNewUser(): Promise<any | User> {
    try {
      return await createProfile();
    } catch (e) {
      return null;
    }
  }
}

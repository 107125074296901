import { NEW_DRS_QUERY } from 'helpers/constants';

export const APP_PATH = '/';
export const SIGNIN_PATH = '/sign-in';
export const SIGNUP_PATH = '/sign-up';
export const SIGNUP_CONFIRMATION_PATH = '/sign-up-confirmation';
export const FORGOT_PASSWORD_REQUEST_PATH = '/forgot-password-request';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const ACCEPT_INVITATION_PATH = '/accept-invitation';

export const ACCOUNT_SETTINGS_BASE_INFO_PATH = '/account-settings/base-info';
export const ACCOUNT_SETTINGS_CHANGE_PASSWORD_PATH =
  '/account-settings/change-password';
export const ACCOUNT_SETTINGS_INVITE_PATH = '/account-settings/invite';

export const HOME_PATH = '/home';
export const HOME_SIMPLE_STORIES_PATH = '/home/simple-stories';
export const HOME_DEVICE_READY_STORIES_PATH = '/home/device-ready-stories';

export const LIBRARY_PATH = '/library';
export const LIBRARY_PATH_ALL = '/library/all';
export const LIBRARY_PATH_PUBLISHED = '/library/published';
export const LIBRARY_PATH_SUBMITTED = '/library/submitted';
export const LIBRARY_PATH_REJECTED = '/library/rejected';
export const LIBRARY_PATH_DRAFTS = '/library/draft';
export const LIBRARY_PATH_BOOKMARKS = '/library/bookmarks';

export const STORIES_PATH = '/stories';
export const STORIES_PATH_CREATE = '/stories/create';
export const STORIES_PATH_EDIT = '/stories/edit';
export const STORIES_PATH_VIEW = '/stories/view';

export const DEVICE_READY_STORY_PATH_CREATE = `${STORIES_PATH_EDIT}/:originalBasicStoryId/robot/:id`;
export const deviceReadyStoryPathCreate = (
  originalBasicStoryId: string,
  robotId: string,
  isNew = false,
) =>
  `${STORIES_PATH_EDIT}/${originalBasicStoryId}/robot/${robotId}${
    isNew ? '?' + NEW_DRS_QUERY : ''
  }`;

export const DEVICES_PATH = '/devices';
